// @use 'abstracts/functions';

.m-skip-link {
  @include button-primary;
  position: fixed;
  top: 0;
  left: 0;
  padding: 1.071em 1.01em 1.071em 1.071em;
  background-color: palette(white);
  transform: translateX(-100%);

  @include focus-default {
    transition: transform 0.2s;
    transform: translateX(0);
  }
}
