.m-map {
  @include fluid-scale(height, 390px, 325px);
  @include fluid-scale(margin-right, 114px, 0px);
  height: 390px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  @supports (object-fit: cover) {
    background-image: none !important; //stylelint-disable-line
  }

  &__marker {
    width: 120px;
    height: 120px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 120 120' width='120' height='120'%3E%3Ccircle cx='60' cy='60' r='57' opacity='.89' fill-rule='evenodd' clip-rule='evenodd' fill='%23fff'/%3E%3Cpath d='M60.5 39C51.9 39 45 45.9 45 54.3c0 3.1.7 5 2.3 7.4L60.5 81l13.2-19.3c1.6-2.4 2.3-4.3 2.3-7.4C76 45.9 69.1 39 60.5 39z' fill-rule='evenodd' clip-rule='evenodd' fill='%23f53'/%3E%3C/svg%3E");
    background-size: cover;
  }
}
