// Variables
// ----------

// Colors
// --------------------------------------------------------

// Primary Colors
//
// Weight: 1
//
// primary - #FF5533;
// primary-light - #FF8067;
//
// Styleguide Abstracts.primaryColors

// Secondary Colors
//
// Weight: 2
//
// Supporting palette with desert hues
//
// sand - #E5A665;
// pink - #FEDBD7;
// sage - #AFC9AD;
// pale-blue - #C8EDF3;
// taupe - #CCC5B3;
// peach - #FFC78D;
// off-white - #FAF9EF;
// light-beige - #F1EFEC;
// light-gray - #F7F7F8
//
// Styleguide Abstracts.secondaryColors

// Neutral Colors
//
// Weight: 3
//
// white - #FFFFFF;
// neutral-10 - #EDEDED;
// neutral-25 - #E4E4E4;
// neutral-30 - #D8D8D8;
// neutral-40 - #9B9B9B;
// neutral-50 - #737379;
// neutral-60 - #67676D;
// neutral-65 - #474748;
// neutral-75 - #2E2E30;
//
// Styleguide Abstracts.neutralColors

$palette: (
  alert: #e01e37,
  primary: #f53,
  primary-light: #ff8067,
  white: #fff,
  black: #000,
  neutral-10: #ededed,
  neutral-25: #e4e4e4,
  neutral-30: #d8d8d8,
  neutral-40: #9b9b9b,
  neutral-45: #848484,
  neutral-50: #737379,
  neutral-60: #67676d,
  neutral-65: #474748,
  neutral-75: #2e2e30,
  sand: #e5a665,
  pink: #fedbd7,
  sage: #afc9ad,
  pale-blue: #c8edf3,
  taupe: #ccc5b3,
  peach: #ffc78d,
  peach-light: #ffd9b2,
  peach-bright: #ffb78f,
  off-white: #faf9ef,
  light-beige: #f1efec,
  light-gray: #f7f7f8,
);

// Responsive breakpoints
// --------------------------------------------------------
$breakpoints: (
  150: 1544px,
  140: 1440px,
  130: 1300px,
  120: 1250px,
  110: 1150px,
  100: 1060px,
  90: 950px,
  85: 850px,
  80: 800px,
  70: 700px,
  60: 600px,
  50: 500px,
  40: 400px,
  30: 320px,
);

// Measurements
// --------------------------------------------------------
$container-widths: (
  max: 1544px,
  x-wide: 1440px,
  wide: 1060px,
  medium-wide: 850px,
  medium: 720px,
  base: 620px,
);
$padding-sizes: (
  x-x-large: 140px,
  x-large: 125px,
  large: 75px,
  medium-large: 60px,
  medium: 50px,
  medium-small: 44px,
  small: 35px,
  x-small: 25px,
  x-x-small: 16px,
);
$duration: 0.2s;
$duration-nav-submenu-short: 0.2s;
$duration-nav-submenu-long: 0.35s;
$timing: ease;

// Typography
// --------------------------------------------------------
$line-height-base: 1.75;
$font-stack-base: 'Metropolis', helvetica, arial, sans-serif;
$font-stack-heading: 'ITCAvantGardeGothic', helvetica, arial, sans-serif;
$regular: 400;
$medium: 500;
$semibold: 600;
$bold: 700;
$leading-base: 0.026em;
$underline-width: 1.5px;

// Appearance
// --------------------------------------------------------
$hover-opacity-90: 0.9;
$hover-opacity-70: 0.7;
$hover-opacity-60: 0.67;

// Cross-component variables
// --------------------------------------------------------
$header-primary-padding-top-large: 36px;
$header-primary-padding-top-small: 22px;
$header-primary-padding-lateral-large: 57px;
$header-primary-padding-lateral-small: 20px;
