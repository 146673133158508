.m-page-content {
  @include fluid-scale(padding-top, 130px, 76px);
  @include fluid-scale(padding-bottom, 185px, padding(medium-large));

  // fix for giant white space at the bottom of some pages in IE11
  overflow: hidden;

  // fix for cut off filters on events pages
  [data-filters-open='true'] & {
    overflow: initial;
  }

  &--blank {
    padding-top: 0;
  }

  &__inner {
    position: relative;
    transition: opacity 0.2s;

    &.is-loading {
      // set a min-height to accommodate height of loader
      @include fluid-scale(min-height, 500px, 230px);
      opacity: 0;
    }

    &.is-loaded {
      transition-delay: 0.4s;
    }

    > *:not(.l-no-top-padding) {
      @include padding-vertical-top-only;
    }

    > *:not(.l-no-bottom-padding) {
      @include padding-vertical-bottom-only;
    }

    // relational layout rules for white containers
    > .a-bg-white {
      &:first-child {
        @include fluid-scale(padding-top, 85px, 42px);
      }

      &:last-child {
        padding-bottom: 0;

        @include respond(break(60)) {
          padding-bottom: 15px;
        }
      }

      &.l-padding-vertical-mobile-flush {
        @include respond(break(60)) {
          padding-top: 0;
        }
      }

      + .a-bg-white {
        padding-top: 0;
      }
    }
  }
}
