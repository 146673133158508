.m-image-callout {
  position: relative;

  &__heading {
    position: absolute;
    top: 70px;
    display: inline-block;
    max-width: 300px;
    padding: padding(medium) 33px 45px;
    background-color: palette(white);

    &--nested {
      right: -5%;
      max-width: 100%;

      @include respond(break(60)) {
        display: block;
        max-width: 445px;
        padding: 10px 0;
      }
    }

    &--full-width {
      right: -15%;

      @include respond(break(60)) {
        max-width: none;
        padding: 0;
      }
    }

    @include respond(break(110)) {
      top: 6vw;
      right: 0;
    }

    @include respond(break(60)) {
      position: relative;
      top: auto;
      width: 100%;
      background-color: inherit;
    }
  }

  &__heading-text {
    transition: opacity 0.2s $timing;
  }

  &__figure {
    display: block;
    transition: background-color 0.5s ease;

    &--cropped {
      max-width: 445px;
      background-color: palette(pink);
    }
  }

  &__hover-fade {
    @include link-image-fade;
  }

  &:hover {
    .m-image-callout__hover-fade.is-loaded {
      @include link-image-hover-opacity;
    }

    .m-image-callout__figure {
      @include link-image-background;
    }

    .m-image-callout__heading-text {
      opacity: $hover-opacity-60;
    }
  }

  &__cropped-image {
    display: block;
    width: 100%;
    max-width: 445px;
    height: calc(0.919 * (50vw - 52.5px));
    max-height: 410px;
    object-fit: cover;

    @include respond(break(60)) {
      height: calc(0.919 * (100vw - 70px));
    }
  }
}
