// Overlay
//
// A flexible module for any kind of container that covers some or all of the window when toggled.
// Modifiers are used to theme `.m-overlay` and include the following:
//
// Size:
// * `&--full`
// * `&--full-mobile`
//
// Position:
// * `&--nested`
//
// Theme:
// * `&--light`
// * `&--dark`
//
// Transition:
// * `&--slide-in`
// * `&--slide-in-mobile`
// * `&--fade-in`
// * `&--fade-in-desktop`
//
// The `&--full` and `&--full-mobile` modifiers uses the CSS Custom Property
// `--vh` for its height. This property is calculated as 1/100th of `window.innerHeight`.
// We use this, with just `1vh` as a fallback, so that this is calculated predictably
// across browsers and devices. The JS class can be found at `SetVhProperty.js`.
//
// Markup: kss-markup/overlay.html
//
// Styleguide Modules.Overlay

.m-overlay {
  @mixin proto-fade-in {
    visibility: hidden;
    opacity: 0;

    &.is-open {
      visibility: visible;
      border-bottom: 1px solid transparent;
      opacity: 1;
      transition: opacity 0.2s linear, visibility 0.2s linear, border 1ms linear;
    }
  }

  @mixin proto-slide-in {
    left: 100%;

    &.is-closed {
      transition: transform 0.5s ease-out;
    }

    &.is-open {
      transition: transform 0.5s ease-out;
      transform: translateX(-100%);
    }
  }

  &.is-open {
    &:not(:focus-within) {
      border-bottom: 2px solid transparent;
    }
  }
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;

  &--padded-vertical {
    @include fluid-scale(padding-top padding-bottom, 42px, 30px);
  }

  &--full {
    height: 100vh;
  }

  &--full-mobile {
    @include respond(break(60)) {
      height: 100vh;
    }
  }

  &--light {
    color: palette(neutral-75);
    background-color: palette(white);
  }

  &--dark {
    color: rgba(palette(white), 0.7);
    background-color: palette(neutral-75);
  }

  &--nested {
    position: fixed;
    margin: 0;
  }

  &--overflow {
    overflow-x: hidden;
    overflow-y: auto;
  }

  &--slide-in {
    @include proto-slide-in;
  }

  &--slide-in-mobile {
    @include respond(break(60)) {
      @include proto-slide-in;
    }
  }

  &--fade-in {
    @include proto-fade-in;
  }

  &--fade-in-desktop {
    @include respond(break(60) + 1, min) {
      @include proto-fade-in;
    }
  }

  &__wrapper {
    @include container-x-wide;
    position: relative;
    height: 100%;

    &--padded {
      @include fluid-scale(
        padding-right padding-left,
        $header-primary-padding-lateral-large,
        padding(small)
      );
    }

    &--pad-top {
      padding-top: 56px;
    }
  }

  &__content,
  &__content-overflow {
    max-width: container-width(medium-wide);
    height: 100%;
    margin: 0 auto;
  }

  &__content {
    overflow-x: hidden;
    overflow-y: auto;
  }

  &__content-overflow {
    overflow: visible;

    .flickity-viewport {
      overflow: visible;
    }
  }

  &__footer {
    position: relative;
  }

  &__back {
    @include fluid-scale(
      left,
      $header-primary-padding-lateral-large,
      padding(small)
    );
    position: absolute;
    top: 0;
    padding-bottom: 20px;
    font-family: $font-stack-heading;
    font-size: 22px;
    font-weight: $medium;
    line-height: 1;
    text-align: left;
    letter-spacing: 0.026em;
  }

  &__close {
    @include fluid-scale(
      right,
      $header-primary-padding-lateral-large,
      padding(small)
    );
    @include hover-recolor;
    position: absolute;
    top: 4px;

    .m-overlay--dark &:hover {
      color: palette(primary-light);
    }

    &--dark {
      top: 0;

      @include fluid-scale(width, 104px, 60px);
      @include fluid-scale(height, 102px, 60px);
      color: palette(white);
      background-color: palette(neutral-75);
    }
  }

  &__icon {
    @include fluid-scale(width height, 32px, 24px);

    &--arrow-left {
      transform: rotate(180deg);
    }
  }
}
