// Primary Button
//
// Markup:
// <button class="m-button-primary {{modifier_class}}">
//   <span>Learn More</span>
//   <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" class="a-icon m-button-primary__icon m-button-primary__icon--arrow"><path d="M22.905 14.665L17.14 8.928l1.878-1.869L28 16l-8.983 8.941-1.878-1.869 5.767-5.738H4v-2.669z"/></svg>
// </button>
// <a href="#" class="m-button-primary {{modifier_class}}">
//   <span>See Gallery</span>
//   <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="a-icon m-button-primary__icon m-button-primary__icon--plus"><path d="M11 5.5h2v13h-2z"/><path d="M5.5 11h13v2h-13z"/></svg>
// </a>
//
// .m-button-primary--light - Has dark text, light fill, and a transparent border
// .m-button-primary--dark - Has light text, a dark fill, and tranparent border
//
// Also has a `&--rte` variant for RTE content that uses a pseudo element for the icon.
//
// Styleguide Modules.PrimaryButton

@mixin button-primary {
  @include button-unstyled;
  @include heading-quinary;
  display: inline-flex;
  align-items: center;
  padding: 1.571em 1.51em 1.571em 1.571em;
  color: palette(neutral-75);
  background-color: transparent;
  border: 1px solid;
  transition:
    background-color $duration ease-out,
    border-color $duration ease-out;

  &:hover {
    border-color: rgba(palette(neutral-75), $hover-opacity-90);
  }
}

.m-button-primary {
  @include button-primary;

  &--light {
    color: palette(neutral-75);
    background-color: palette(white);
    border-color: transparent;

    &:hover {
      background-color: rgba(palette(white), $hover-opacity-90);
      border-color: transparent;
    }
  }

  &--dark {
    color: palette(white);
    background-color: palette(neutral-75);
    border-color: transparent;

    &:hover {
      background-color: rgba(palette(neutral-75), $hover-opacity-90);
      border-color: transparent;
    }
  }

  &:disabled,
  &[disabled],
  &[aria-disabled='true'] {
    pointer-events: none;
    cursor: default;
  }

  &__icon {
    flex-shrink: 0;
    width: 1.393em;
    height: 1.393em;
    margin-left: 4em;

    &--arrow {
      transition: transform $duration ease-out;

      .m-button-primary:hover & {
        transform: translateX(5px);
      }
    }

    &--plus {
      transition: transform 0.15s ease;

      .m-button-primary:hover & {
        transform: scale(1.19);
      }
    }
  }

  &:disabled &__icon,
  &[disabled] &__icon,
  &[aria-disabled='true'] &__icon {
    display: none;
  }

  &--rte-dark {
    color: palette(white);
    background-color: palette(neutral-75);
    border-color: transparent;

    &::after {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cpath d='M22.905 14.665L17.14 8.928l1.878-1.869L28 16l-8.983 8.941-1.878-1.869 5.767-5.738H4v-2.669z' fill='white' /%3E%3C/svg%3E");
    }

    &:hover {
      background-color: rgba(palette(neutral-75), $hover-opacity-90);
      border-color: transparent;
    }
  }

  &--rte-light {
    color: palette(neutral-75);
    background-color: palette(white);
    border-color: transparent;

    &:hover {
      background-color: rgba(palette(white), $hover-opacity-90);
      border-color: transparent;
    }
  }

  /* stylelint-disable no-descending-specificity */
  &--rte,
  &--rte-dark,
  &--rte-light {
    // We don't typically do this, but this class is added to links just in the RTE, so it's not properly a modifier
    @include button-primary;

    &::after {
      display: inline;
      width: 1.393em;
      min-width: 18px;
      height: 1.393em;
      min-height: 18px;
      margin-left: 4em;
      vertical-align: middle;
      content: '';
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cpath d='M22.905 14.665L17.14 8.928l1.878-1.869L28 16l-8.983 8.941-1.878-1.869 5.767-5.738H4v-2.669z' fill='%232E2E30' /%3E%3C/svg%3E");
      background-repeat: no-repeat;
      transition: transform $duration ease-out;
    }

    &:hover {
      &::after {
        transform: translateX(5px);
      }
    }
  }
}
