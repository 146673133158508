// Form
//
// Markup:
// <form class="m-form l-flex">
//   <input type="text" placeholder="Input text" class="m-form__input m-form__input--text">
//   <button class="m-form__input m-form__input--submit" type="submit">
//     <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" class="a-icon m-form__submit-icon">
//   	  <path d="M22.905 14.665L17.14 8.928l1.878-1.869L28 16l-8.983 8.941-1.878-1.869 5.767-5.738H4v-2.669z" />
//     </svg>
//   </button>
// </form>
//
// Styleguide Modules.Form

.m-form {
  &--dark {
    color: rgba(palette(white), 0.7);
  }

  // display form as a large heading
  &--heading {
    @include heading-primary;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__input {
    letter-spacing: 0.023em;
    background-color: transparent;
    border-radius: 0;
    appearance: none;

    &:focus {
      outline: none;
    }
  }

  &__input--submit {
    @include fluid-scale(padding-bottom, 23px, 10px);
    padding-right: 0.25em;
    padding-left: 1em;
    border: none;

    .m-form--dark & {
      border-color: rgba(palette(white), 0.5);
    }
  }

  &__input--text {
    @include fluid-scale(padding-bottom, 23px, 10px);
    @include heading-tertiary;
    border: none;
  }

  &__input--text-large {
    @include proto-heading;
    @include fluid-scale(font-size, 32px, 18px);
    padding-bottom: 10px;
    border: none;
    border-bottom: 1px solid;

    .m-form--dark & {
      border-color: rgba(palette(white), 0.5);
    }
  }

  /* stylelint-disable no-descending-specificity */
  &__input--text,
  &__input--submit {
    &:not(.a-no-underline) {
      border-bottom: 1px solid;
    }
  }

  &__select {
    @include focus-default;
    @include hover-recolor;
    padding-right: 15px;
    cursor: pointer;
    background: none;
    border: none;
    appearance: none;

    option:not(:checked) {
      color: black; /* prevent <option>s from inheriting :focus color */
    }

    &:-moz-focusring {
      outline: none;
    }

    &::-ms-expand {
      display: none;
    }
  }

  &__caret-icon {
    bottom: 0;
    width: 20px;
    height: 20px;
    margin-bottom: 5px;
    color: palette(primary);
  }

  &__submit-icon {
    @include fluid-scale(width height, 36px, 19.5px);
    opacity: 0.4;
    transition: transform $duration ease-out;

    .m-form__input--submit:hover & {
      transform: translateX(5px);
    }

    .m-form__input--submit.focus-visible & {
      outline: palette(primary) auto 4px;
      outline-offset: -3px;
    }

    @include respond(break(60)) {
      opacity: 1;
    }

    .m-form--dark & {
      opacity: 0.7;
    }
  }
}
