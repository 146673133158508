// Card
//
// A vertically-stacked block with an image and text. Images use `object-fit` for consistent, proportionally-scaled sizing.
//
// Markup: kss-markup/card.html
//
// Styleguide Modules.Card

.m-card {
  display: flex;
  flex-direction: column;
  color: palette(neutral-75);

  &--small {
    width: 233px;
  }

  &--large {
    @include fluid-scale(width, 504px, 305px);
  }

  &__content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    order: 1;
    height: 100%;
    padding-top: 25px;
    padding-bottom: 20px;

    &--padded {
      padding-right: 20px;
      padding-left: 20px;
    }
  }

  &__body {
    flex-grow: 1;
  }

  &__tag {
    @include heading-quinary;
    margin-bottom: 12px;
  }

  &__title {
    @include heading-tertiary;
    line-height: 1.278;
  }

  &__small-title {
    @include heading-senary;
    margin-bottom: 12px;
    font-weight: $semibold;
  }

  &__footer {
    @include copy-small;
    padding-top: 32px;
  }

  &__figure {
    background-color: palette(light-gray); //palette(pink);
    transition: background-color 0.5s ease;

    [href]:hover &,
    [href]:focus & {
      background-color: palette(pink);
    }
  }

  &__image {
    display: block;
    width: 100%;
    object-fit: cover;
    backface-visibility: hidden; // prevents jumping on opacity change
    transition: opacity 0.5s ease;

    .m-card--small & {
      height: 128px;
    }

    .m-card--large & {
      @include fluid-scale(height, 426px, 231px);
    }

    [href]:hover &,
    [href]:focus & {
      opacity: 0.333;
    }
  }
}
