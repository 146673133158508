// Waypoint
//
// Waypoints are invisible in-page anchors. They have a few properties set just
// to ensure that they don't take up any space on the page.
//
// Styleguide Modules.Waypoint

.m-waypoint {
  padding: 0 !important; /* stylelint-disable-line declaration-no-important */
  font-size: 0;
}
