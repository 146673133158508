.m-footer-primary {
  @include fluid-scale(padding-top, 136px, 60px);
  @include fluid-scale(padding-bottom, 104px, 60px);
  color: palette(white);
  background-color: palette(neutral-75);

  &--minimal {
    padding-top: padding(small);
    padding-bottom: padding(small);
  }

  @include respond(break(60)) {
    padding-top: 38px;
    padding-bottom: padding(x-small);
  }

  &__row {
    & + & {
      @include fluid-scale(padding-top, 80px, 40px);
      @include fluid-scale(margin-top, 93px, 40px);
      border-top: 1px solid rgba(palette(white), 0.23);
    }
  }

  &__heading {
    @include proto-heading;
    font-size: 32px;
    line-height: 1;
    letter-spacing: 0.026em;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__item {
    flex-basis: calc(25% - 25px);
    flex-grow: 1;

    &:not(:last-child) {
      margin-right: 25px;
    }

    &--wide {
      flex-basis: auto;

      @include respond(break(80)) {
        flex-basis: 100%;
        margin-bottom: 45px;
      }
    }
  }

  &__colophon {
    @include fluid-scale(margin-top, 100px, padding(small));
    @include fluid-scale(font-size, 15px, 14px);
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    font-family: $font-stack-base;
    font-weight: $semibold;
    line-height: 2;
    letter-spacing: 0.026em;

    @include respond(break(60)) {
      flex-direction: column-reverse;
      align-items: flex-start;
    }

    .m-footer-primary--minimal & {
      margin-top: 0;
    }
  }

  &__colophon-copy {
    @include respond(break(60)) {
      padding-top: 20px;
      color: palette(neutral-40);
    }

    a {
      margin-right: 32px;
    }
  }
}
