.m-search-results {
  list-style-type: none;

  &__result-outer {
    &:not(:last-child) {
      padding-bottom: padding(small);
      border-bottom: 1px solid palette(neutral-30);

      @include respond(break(60)) {
        padding-bottom: 0;
        border-bottom: none;
      }
    }

    &:not(:first-child) {
      padding-top: padding(small);
    }
  }

  &__result-inner {
    display: flex;

    @include respond(break(60)) {
      flex-direction: column;
    }
  }

  &__title {
    @include fluid-scale(padding-bottom, padding(x-small), 4px);
  }

  &__info,
  &__thumbnail {
    @include fluid-scale(margin-right, padding(medium-large), padding(x-small));

    @include respond(break(60)) {
      margin-right: 0;
    }
  }

  &__info {
    @include respond(break(60)) {
      order: 3;
    }
  }

  &__thumbnail {
    width: 184px;
    height: 136px;
    object-fit: cover;

    @include respond(break(60)) {
      width: 100%;
      height: 207px;
      margin-bottom: padding(x-small);
    }
  }

  &__category {
    margin-left: auto;

    @include respond(break(60)) {
      order: 2;
      margin-bottom: 18px;
      margin-left: 0;
    }
  }
}
