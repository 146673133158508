.m-lazy-image {
  $not-selectors: ':not(.is-loaded):not(.flickity-lazyloaded):not(.flickity-lazyerror)';
  opacity: 0;
  transition: opacity 0.4s 0.1s;

  [href]:hover &#{$not-selectors},
  [href].focus-visible &#{$not-selectors} {
    opacity: 0;
  }

  &#{$not-selectors} {
    &:hover,
    &.focus-visible {
      opacity: 0;
    }
  }

  &.is-loaded,
  &.flickity-lazyloaded,
  &.flickity-lazyerror {
    opacity: 1;
  }

  &--blurred {
    display: block;
    transition: filter 0.4s 0.1s, opacity 0.4s ease;

    &.is-loading {
      filter: blur(12px);
    }

    &.is-loaded {
      filter: blur(0);
    }
  }
}
