.m-detail-summary {
  position: relative;

  &__date-time-wide {
    @include fluid-scale(padding, padding(large), padding(small));
    max-width: 721px;
    margin-bottom: -60px;
    transform: translateY(-190px);

    @include respond(break(90)) {
      display: none;
    }
  }

  &__date-time-narrow {
    display: none;

    @include respond(break(90)) {
      display: block;
    }
  }

  &__pricing-container-wide {
    position: absolute;
    top: 70px;
    right: 0;
    width: 280px;
    margin-right: 140px;

    @include respond(break(120)) {
      display: none;
    }

    .m-see-gallery {
      margin-top: padding(small);
    }
  }

  &__pricing-container-narrow {
    display: none;

    @include respond(break(120)) {
      @include fluid-scale(padding-bottom, padding(large), padding(small));
      display: block;
      margin-top: -95px;
    }

    @include respond(break(90)) {
      margin-top: 0;
    }

    .m-see-gallery {
      margin-top: padding(small);
    }
  }

  &__button {
    justify-content: space-between;
    width: 100%;
    margin-bottom: padding(x-x-small);
    text-align: left;

    @include respond(break(120)) {
      display: flex;
      width: 280px;
    }

    @include respond(break(60)) {
      max-width: 100%;
    }
  }

  &__pricing-options-container {
    color: palette(neutral-60);
  }

  &__pricing-option {
    display: block;
  }

  &__row-head {
    @include fluid-scale(width, 155px, 100px);
    text-align: left;
  }

  &__info-cell {
    padding-top: 15px;
    padding-bottom: 10px;
  }

  &__accordion-container {
    @include fluid-scale(padding-top, 40px, padding(x-small));
  }

  &__accordion-inner {
    @include fluid-scale(padding-top, 20px, 10px);
  }
}
