.m-hero-header-primary {
  /* stylelint-disable property-no-vendor-prefix */
  @include container;
  position: relative;
  color: palette(white);
  background-color: palette(neutral-60);

  &__inner {
    position: relative;
    display: grid;
    -ms-grid-rows: 30% 1fr auto;
    -ms-grid-columns: 100%;
    grid-template:
      '.' 30%
      'body' 1fr
      'footer' auto / 100%;
    height: calc(716px + var(--header-height, 130px));
    padding-right: padding(small);
    padding-left: padding(small);
    margin-top: calc(var(--header-height, 130px) * -1);
    pointer-events: none;
    background:
      linear-gradient(
        to top,
        rgba(0, 0, 0, 60%) 0%,
        rgba(0, 0, 0, 0%) 35%
      );

    /* stylelint-disable scss/media-feature-value-dollar-variable */
    @media all and (max-height: break(85)) {
      height: calc(var(--vh, 1vh) * 100);
    }
    /* stylelint-enable scss/media-feature-value-dollar-variable */
  }

  &__body {
    display: flex;
    flex-direction: column;
    grid-area: body;
    -ms-grid-row: 2;
    -ms-grid-column: 1;
    justify-content: space-between;
    height: 100%;
    text-align: center;
    visibility: hidden;
    opacity: 0;
    transition: none;

    &--active {
      visibility: visible;
      opacity: 1;
      transition: opacity 0.25s 0.15s linear, visibility 0.25s 0.15s linear;
    }

    &--inactive {
      visibility: hidden;
      opacity: 0;
      transition: opacity 0.25s 0.25s linear, visibility 0.25s 0.25s linear;
    }
  }

  &__header {
    text-align: center;
  }

  &__heading {
    @include fluid-scale(font-size, 60px, 32px);
    letter-spacing: 0.034em;
  }

  &__subheading {
    @include heading-quaternary;
    font-size: 19px;
    letter-spacing: 0.057em;
  }

  &__cta {
    @include button-primary;
    justify-content: center;
    min-width: 282px;
    margin-top: 50px;
    color: inherit;
    pointer-events: auto;
    transition: color $duration ease-out, border-color $duration ease-out;

    @include respond(break(70)) {
      width: 75vw;
    }

    /* stylelint-disable scss/media-feature-value-dollar-variable */
    @media all and (max-height: break(85)), (max-width: break(70)) {
      margin-top: 24px;
    }
    /* stylelint-enable scss/media-feature-value-dollar-variable */

    &:hover,
    &:focus {
      color: palette(primary);
      border-color: palette(primary);
    }
  }

  &__footer {
    @include fluid-scale(margin-bottom, 50px, 12px);
    grid-area: footer;
    -ms-grid-row: 3;
    -ms-grid-column: 1;
    text-align: center;
  }

  &__footer-progress {
    @include fluid-scale(margin-top, 30px, 12px);
    display: flex;
    width: 75vw;
    max-width: 558px;
    margin-right: auto;
    margin-left: auto;
  }

  &__progress-bar {
    appearance: none;
    flex-grow: 1;
    height: 2px;
    color: palette(primary); // IE11
    border: none;

    &::-moz-progress-bar {
      background-color: palette(primary);
    }

    &::-webkit-progress-bar {
      background-color: rgba(palette(white), 0.2);
    }

    &::-webkit-progress-value {
      background-color: palette(primary);
    }

    & + & {
      margin-left: 14px;
    }
  }

  &__footer-scroll {
    @include hover-recolor;
    display: none;
    padding: 1em;
    margin: 12px auto 0;
    font-size: 0;
    color: rgba(palette(white), 0.5);
    /* stylelint-disable scss/media-feature-value-dollar-variable */
    @media all and (max-height: break(85)), (max-width: break(70)) {
      display: block;
    }

    @media all and (max-height: break(85)) and (max-aspect-ratio: 1 / 1) {
      margin-top: 43px;
    }
    /* stylelint-enable scss/media-feature-value-dollar-variable */
  }

  &__scroll-icon {
    fill: currentcolor;
  }

  &__slide-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__full-slide-link {
    width: 100%;
    height: 100%;
  }

  &__picture {
    width: 100%;
    height: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
