.m-navigation-secondary {
  @include list-unstyled;
  display: flex;

  // justify-content: space-between;
  color: palette(white);

  &__item {
    flex-grow: 1;
    min-width: 15%;
    max-width: 20%;
    padding-left: padding(x-small);

    &:first-child {
      margin-left: padding(x-small) * -1;
    }
  }

  &__link {
    display: block;
    padding-top: 3px;
    padding-bottom: 3px;
    font-size: 15px;

    &:hover {
      text-decoration: underline;
    }
  }

  &__submenu {
    @include list-unstyled;
    color: rgba(palette(white), 0.5);
  }
}
