// Card
//
// A block with text overlaying a background image. Images use `object-fit` for consistent, proportionally-scaled sizing.
// Typically nested inside of a slider, in which case the title and a faint gradient appear over the image on the active slide.
//
// Markup: kss-markup/overlay-card.html
//
// Styleguide Modules.CardOverlay

.m-overlay-card {
  @include fluid-scale(width, 873px, 424px, break(110), break(70));
  @include fluid-scale(height, 494px, 240px, break(110), break(70));
  position: relative;
  display: flex;
  flex-direction: column;
  width: 873px;
  color: palette(neutral-75);

  @include respond(break(70)) {
    width: 305px;
    height: auto;
  }

  &__content {
    @include fluid-scale(padding-top padding-bottom, padding(small), padding(x-small));
    @include fluid-scale(padding-right padding-left, padding(large), padding(x-small));
    height: 100%;

    @include respond(break(70)) {
      padding-left: 0px;
    }

    @include respond(break(70) + 1, min) {
      &::before {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -25;
        width: 100%;
        height: 100%;
        content: '';
        background: linear-gradient(rgba(palette(pink), 0), palette(pink));
        transition: opacity 0.25s 0.1s ease;

        .m-slider__child:not(.is-selected) & {
          opacity: 0;
        }
      }
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
  }

  &__tag {
    @include fluid-scale(margin-bottom, 20px, 12px);
    @include heading-quinary;

    @include respond(break(70)) {
      color: palette(neutral-60);
    }
  }

  &__title {
    width: 75%;

    @include respond(break(140)) {
      width: 100%;
    }

    @include respond(break(70) + 1, min) {
      @include heading-secondary;
      line-height: 1.219;
    }

    @include respond(break(70)) {
      @include heading-senary;
      font-weight: $semibold;
    }
  }

  &__desc {
    @include copy-primary;
    margin-top: 10px;
  }

  &__tag,
  &__title,
  &__desc {
    transition: opacity 0.4s 0.4s ease;

    .m-slider__child:not(.is-selected) & {
      @include respond(break(70) + 1, min) {
        opacity: 0;
      }
    }
  }



  &__figure {
    z-index: -50;
    width: 100%;
    height: 231px;
    background-color: palette(pink);

    @include respond(break(70)) {
      order: -1;
    }

    @include respond(break(70) + 1, min) {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
    }
  }

  &__image {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    backface-visibility: hidden; // prevents jumping on opacity change
    transition: opacity 0.5s ease;

    [href]:hover &,
    [href]:focus & {
      opacity: 0.333;
    }
  }
}
