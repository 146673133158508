.m-content-rte {
  @include copy-primary;
  color: var(--text-color, palette(neutral-75));

  // Headings
  // --------------------------
  &.m-two-columns h1 {
    margin-bottom: 65px;
  }

  /* stylelint-disable no-descending-specificity */
  h1,
  h2,
  h3 {
    margin-bottom: padding(x-small);
    color: var(--text-color, palette(neutral-75));
  }

  h1,
  h2,
  h3,
  h4 {
    &:not(:first-child) {
      @include fluid-scale(margin-top, 20px, padding(x-x-small));
    }
  }

  h1 {
    @include heading-primary;
  }

  h2 {
    @include heading-secondary;
  }

  h3 {
    @include heading-tertiary;
  }

  h4 {
    @include heading-senary;
    color: var(--text-color, palette(neutral-75));
  }

  // special class that can be applied to paragraphs to simulate an h5
  .t-heading-quinary {
    margin-bottom: -5px;
    color: var(--text-color, palette(neutral-75));
  }

  // Copy
  // --------------------------
  p,
  ul,
  ol {
    &:not(:first-child) {
      margin-top: 20px;
    }

    a:not([class^='m-button-'], .m-download-link, .m-arrow-link) {
      color: palette(neutral-75);
      text-decoration: underline;
      transition: color $duration ease;

      &:hover {
        color: palette(primary);
      }
    }
  }

  ol {
  }

  ul {
  }

  hr {
    @include fluid-scale(
      margin-top margin-bottom,
      padding(large),
      padding(medium)
    );
    border-color: palette(neutral-25);
  }

  // Buttons/Links
  // --------------------------
  [class^='m-button-'] {
    @include fluid-scale(margin-top, padding(x-small), padding(x-x-small));
  }

  // Images
  // --------------------------
  p {
    > img {
      max-width: 100%;
    }
  }
}
