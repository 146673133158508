// Component Layout
// -------------------

// Layout-only mixins and utility classes.

// They may contain:
// Nested classes/elements that have structural parameters only

// Utility Spacing Classes
// --------------------------------------------------------

// Create a a padding class in each direction for each value in $padding-sizes map
@each $name, $value in $padding-sizes {
  .l-pad-top-#{$name} {
    padding-top: $value;
  }

  .l-pad-right-#{$name} {
    padding-right: $value;
  }

  .l-pad-bottom-#{$name} {
    padding-bottom: $value;
  }

  .l-pad-left-#{$name} {
    padding-left: $value;
  }
}

// used to selectively apply lateral spacing to content when its wrapper is full-bleed
// see mobile primary nav & secondary nav menus for examples
.l-padded-item {
  @include fluid-scale(
    margin-right margin-left,
    $header-primary-padding-lateral-large,
    padding(small)
  );
}

.l-left-padded-item {
  @include fluid-scale(
    padding-left,
    $header-primary-padding-lateral-large,
    padding(small)
  );
}

// Padding Vertical
//
// Adds top and bottom padding to an `.l-container` variant
//
// Weight: 6
//
// Usage: `.l-padding-vertical` or `@include padding-vertical`
//
// Markup:
// <div class="l-container" style="background:#f5f5cf;">
//   <div class="l-container-secondary l-padding-vertical" style="background:#b2d2b2;">
//     <h4 class="t-heading-quaternary" style="height:200px;background:#cedcef;">Some content</h4>
//   </div>
// </div>
//
// Styleguide Utility.Layout.PaddingVertical

@mixin padding-vertical {
  @include fluid-scale(
    padding-top padding-bottom,
    padding(x-large),
    padding(large)
  );
}

@mixin padding-vertical-small {
  @include fluid-scale(
    padding-top padding-bottom,
    padding(medium),
    padding(small)
  );
}

@mixin padding-vertical-top-only {
  @include fluid-scale(padding-top, padding(x-large), padding(large));
}

@mixin padding-vertical-bottom-only {
  @include fluid-scale(padding-bottom, padding(x-large), padding(large));
}

.l-padding-vertical {
  @include padding-vertical;
}

.l-padding-vertical-small {
  @include padding-vertical-small;
}

// like `l-padding-vertical`, but with no top padding on mobile
.l-padding-vertical-mobile-flush {
  @include fluid-scale(
    padding-top padding-bottom,
    padding(x-large),
    padding(large),
    break(110),
    break(80)
  );

  @include respond(break(60)) {
    padding-top: 0;
    padding-bottom: padding(medium);
  }
}

.l-no-mobile-padding {
  @include respond(break(60)) {
    padding-top: 0 !important; /* stylelint-disable-line */
    padding-bottom: 0 !important; /* stylelint-disable-line */
  }
}

.l-no-tablet-padding {
  @include respond(break(80)) {
    padding-top: 0 !important; /* stylelint-disable-line */
    padding-bottom: 0 !important; /* stylelint-disable-line */
  }
}

//adds vertical padding to nested elements
.l-padding-vertical-nested {
  + .l-padding-vertical-nested {
    @include fluid-scale(padding-top, padding(x-large), padding(large));
  }
}

.l-caption-padding {
  margin-bottom: 10px;
}

.l-breakout {
  @include fluid-scale(padding-top, 83px, padding(medium-large));
  position: relative;
  padding-top: 83px;

  &::before {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: calc(100% - 490px);
    content: '';
    background-color: palette(white);

    @include respond(break(110)) {
      height: calc(100% - 40vw);
    }

    @include respond(break(60)) {
      height: calc(100% - 290px);
    }
  }
}

// Utility Layout Classes
// --------------------------------------------------------
.l-flex {
  display: flex;
}

.l-flex--flow {
  flex-wrap: wrap;
}

.l-flex--column {
  flex-direction: column;
}

.l-flex--aic {
  align-items: center;
}

.l-flex--sb {
  justify-content: space-between;

  &.l-flex--column > * + * {
    margin-top: padding(x-x-small);
  }

  &:not(.l-flex--column) > * + * {
    margin-left: padding(x-small);
  }
}

.l-flex--center {
  justify-content: center;
}

.l-flex--column-mobile {
  @include respond(break(60)) {
    flex-direction: column;
  }
}

.l-flex--column-mid {
  @include respond(break(90)) {
    flex-direction: column;
  }
}

.l-flex-child--fixed-width {
  max-width: 360px;

  &:not(:first-child) {
    margin-left: 40px;

    @include respond(break(90)) {
      margin-left: 0;
    }
  }

  &:not(:last-child) {
    margin-right: 40px;

    @include respond(break(90)) {
      margin-right: 0;
    }
  }

  @include respond(break(90)) {
    max-width: none;
  }
}

.l-flex-child--mobile-padding {
  @include respond(break(90)) {
    padding-top: padding(small);
  }
}

.l-grow {
  flex-grow: 1;
}

.l-order-1 {
  order: 1;
}

// Containers
// --------------------------------------------------------

@mixin proto-container($max-width: container-width(x-wide)) {
  max-width: $max-width + 2 * padding(small);
  padding-right: padding(small);
  padding-left: padding(small);
  margin-right: auto;
  margin-left: auto;
}

// Container
//
// The highest-level container for page content. Sets a max-width and centers its children.
//
// Weight: 1
//
// Usage: `.l-container` or `@include container`
//
// Markup:
// <div class="l-container" style="height:200px;background:#cedcef;"></div>
//
// Styleguide Utility.Layout.Container

@mixin container {
  width: 100%;
  max-width: container-width(max);
  margin-right: auto;
  margin-left: auto;
}

.l-container {
  @include container;
}

.l-container-heading {
  @include fluid-scale(margin-bottom, padding(medium-large), padding(small));
}

.l-container-heading-small {
  margin-bottom: 30px;
}

// Container Extra-Wide
//
// Lives inside `.l-container`. A wide container with no left or right padding.
//
// Weight: 2
//
// Usage: `.l-container-x-wide` or `@include container-x-wide`
//
// Markup:
// <div class="l-container" style="background:#f5f5cf;">
//   <div class="l-container-x-wide" style="background:#b2d2b2;">
//     <h4 class="t-heading-quaternary" style="height:200px;background:#cedcef;">Some content</h4>
//   </div>
// </div>
//
// Styleguide Utility.Layout.ContainerExtraWide

@mixin container-x-wide {
  max-width: container-width(x-wide);
  margin: 0 auto;
}

.l-container-x-wide {
  @include container-x-wide;
}

// Container Wide
//
// Lives inside `.l-container`. Has a max-width of `container-width(wide)` and left and right padding of `padding(small)`.
//
// Weight: 3
//
// Usage: `.l-container-wide` or `@include container-wide`
//
// Markup:
// <div class="l-container" style="background:#f5f5cf;">
//   <div class="l-container-wide" style="background:#b2d2b2;">
//     <h4 class="t-heading-quaternary" style="height:200px;background:#cedcef;">Some content</h4>
//   </div>
// </div>
//
// Styleguide Utility.Layout.ContainerWide

@mixin container-wide {
  @include proto-container(container-width(wide));
}

.l-container-wide {
  @include container-wide;
}

// Container Primary
//
// Lives inside `.l-container`. Has a max-width of `container-width(primary)` and left and right padding of `padding(small)`.
//
// Weight: 4
//
// Usage: `.l-container-primary` or `@include containerPrimary`
//
// Markup:
// <div class="l-container" style="background:#f5f5cf;">
//   <div class="l-container-primary" style="background:#b2d2b2;">
//     <h4 class="t-heading-quaternary" style="height:200px;background:#cedcef;">Some content</h4>
//   </div>
// </div>
//
// Styleguide Utility.Layout.ContainerPrimary

@mixin container-primary {
  @include proto-container(container-width(medium));
}

.l-container-primary {
  @include container-primary;
}

// Container Secondary
//
// Similar to Container Primary, but slightly narrower.
//
// Weight: 5
//
// Usage: `.l-container-secondary` or `@include containerSecondary`
//
// Markup:
// <div class="l-container" style="background:#f5f5cf;">
//   <div class="l-container-secondary" style="background:#b2d2b2;">
//     <h4 class="t-heading-quaternary" style="height:200px;background:#cedcef;">Some content</h4>
//   </div>
// </div>
//
// Styleguide Utility.Layout.ContainerSecondary

@mixin container-secondary {
  @include proto-container(container-width(base));
}

.l-container-secondary {
  @include container-secondary;
}

.l-container-secondary-nested-wide {
  @include fluid-scale(padding-right padding-left, padding(small), 0);
  /* stylelint-disable-next-line no-eol-whitespace */
  @include fluid-scale(max-width, container-width(base) + 2 * padding(small), 510px);
  margin-right: auto;
  margin-left: auto;
}

.l-container-secondary-nested {
  @include fluid-scale(padding-right padding-left, padding(small), 0px);
  
  @include respond(break(60), min) {
    max-width: 505 + 2 * padding(small);
    margin-right: auto;
    margin-left: auto;
  }
}
