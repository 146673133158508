.m-event-list {
  &__heading-container {
    @include fluid-scale(margin-bottom, padding(x-large), padding(small));
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    @include respond(break(80)) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__heading-link {
    @include respond(break(80)) {
      display: none;
    }
  }

  &__button-container {
    @include fluid-scale(margin-top, 105px, padding(small));
    display: flex;
    align-items: center;
    justify-content: center;

    &--mobile-only {
      display: none;

      @include respond(break(80)) {
        display: flex;
      }
    }
  }

  &__button {
    @include respond(break(50)) {
      justify-content: space-between;
      width: 100%;
    }
  }

  &__list {
    @include list-unstyled;
    display: flex;
    flex-wrap: wrap;

    @supports (grid-template-columns: 1fr 1fr) {
      @include fluid-scale(grid-row-gap, 95px, 50px);
      @include fluid-scale(grid-column-gap, padding(medium-large), padding(x-small));
      display: grid;
      grid-template-columns: 1fr 1fr;

      @include respond(break(80)) {
        grid-template-columns: 1fr;
      }
    }
  }

  &__preview-container {
    width: 50%;

    &:not(:last-child) {
      @include fluid-scale(padding-bottom, 95px, 50px);
    }

    &:nth-child(odd) {
      @include fluid-scale(padding-right, padding(medium-large), padding(x-small));
    }

    @include respond(break(80)) {
      width: 100%;

      &:nth-child(odd) {
        padding-right: 0;
      }
    }

    @supports (grid-template-columns: 1fr 1fr) {
      width: unset;

      &:not(:last-child) {
        padding-bottom: unset;
      }

      &:nth-child(odd) {
        padding-right: unset;
      }
    }
  }

  &__thumbnail {
    @include link-image-fade;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__name {
    @include fluid-scale(padding-bottom, 12px, 2px);
    transition: opacity 0.2s $timing;
  }

  &__preview {
    display: flex;

    @include respond(break(50)) {
      margin-right: -35px;
      overflow: hidden;
    }

    &:hover {
      .m-event-list__thumbnail {
        @include link-image-hover-opacity;
      }

      .m-event-list__name {
        opacity: $hover-opacity-60;
      }
    }
  }

  &__thumbnail-container {
    @include fluid-scale(margin-right, padding(small), padding(x-x-small), break(110), break(90));
    @include link-image-background;
    @include fluid-scale(width, 177px, 97px);
    @include fluid-scale(height, 130px, 100px);
    display: block;

    @include respond(break(80)) {
      order: 2;
      margin-right: 0;
      margin-left: auto;
    }
  }

  &__category {
    @include fluid-scale(padding-bottom, 14px, 4px);
  }

  &__text-container {
    flex: 1;

    @include respond(break(80)) {
      padding-right: padding(small);
    }
  }
}
