.m-accordion-list {
  h2,
  h3 {
    color: palette(neutral-75);
  }

  &__heading-container {
    @include fluid-scale(padding-bottom, padding(medium-large), padding(medium-small));
    border-bottom: 1px solid palette(neutral-40);
  }

  &__content-container-outer {
    @include fluid-scale(margin-right margin-left, padding(medium-large), padding(medium-small));
    max-width: 911px;
    margin: 0 auto;
  }

  &__subheading {
    @include fluid-scale(padding-top, 56px, padding(small));
  }

  &__accordion-container {
    &:not(:last-child) {
      border-bottom: 1px solid palette(neutral-40);
    }
  }

  &__preview-container {
    @include fluid-scale(padding-top, padding(medium-large), padding(medium-small));
    display: flex;
    align-items: center;
    margin-right: auto;
    margin-left: auto;

    &:not(:last-child) {
      @include fluid-scale(padding-bottom, padding(medium-large), padding(medium-small));
    }
  }

  &__toggle-container {
    padding-right: 25px;
    padding-left: 25px;
  }

  &__toggle-button {
    width: 35px;
    height: 35px;

    > svg {
      width: 35px;
      height: 35px;
      fill: palette(primary);
    }
  }

  &__question-heading {
    @include fluid-scale(padding-right padding-left, padding(small), 0);
    @include fluid-scale(max-width, 610px, 425px);

    //work-around for IE11 issue with centering in flex
    @include fluid-scale(margin-left, 107px, 0);
    flex-grow: 1;

    @supports (object-fit: cover) {
      margin-right: auto;
      margin-left: auto;
    }
  }

  &__full-content {
    @include fluid-scale(padding-bottom, padding(medium-large), padding(medium-small));
  }

  &__cta-container {
    @include respond(break(60)) {
      flex-direction: column;
    }
  }

  &__cta-heading {
    flex: 1;

    &:not(:last-child) {
      padding-right: padding(small);
    }

    @include respond(break(60)) {
      flex-basis: auto;
      width: 100%;
      padding-right: 0;
    }
  }

  &__cta-button-container {
    @include respond(break(60)) {
      flex-basis: auto;
      width: 100%;

      &:not(:first-child) {
        padding-top: padding(x-small);
      }
    }
  }
}
