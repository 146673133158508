// Entity Thumbnail
//
// A media block containing a heading, thumbnail image, and some kind of descriptive information.
// Usually linked to a detail page for that thumb.
//
// Markup: kss-markup/entity-thumbnail.html
//
// Styleguide Modules.EntityThumbnail

.m-entity-thumbnail {
  color: palette(neutral-75);

  &__link {
    display: block;
    color: inherit;
    text-decoration: none;
  }

  &__inner {
    display: flex;
    flex-direction: column;
  }

  &__heading {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
  }

  &__title {
    @include fluid-scale(margin-bottom, padding(x-small), 12px);
    @include heading-quaternary;

    @include respond(break(60)) {
      font-size: 24px;
      line-height: 1.417;
      letter-spacing: 0;
    }
  }

  &__category {
    @include heading-quinary;
    order: -1;
    margin-bottom: padding(x-x-small);
    color: palette(neutral-60);
    letter-spacing: 0.026em;
  }

  &__description {
    @include copy-primary;
  }

  &__figure {
    order: -1;
    height: 100%;
    background-color: palette(pink);
  }

  &__image {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
    transition: opacity 0.5s ease;

    @supports (object-fit: cover) {
      @include fluid-scale(height, 250px, 228px);
      object-fit: cover;

      @include respond(647px) {
        height: auto;
      }
    }

    .m-entity-thumbnail__link:hover &,
    .m-entity-thumbnail__link:focus & {
      opacity: 0.333;
    }
  }
}
