.m-instagram {
  &__heading-container {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    @include respond(break(80)) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__button-container {
    min-width: 315px;

    @include respond(break(80)) {
      min-width: 0;
    }

    &:only-child {
      display: flex;
      justify-content: flex-end;
      width: 100%;

      @include respond(break(80)) {
        justify-content: flex-start;
      }
    }
  }

  &__main-heading {
    &:not(:last-child) {
      @include fluid-scale(padding-bottom, padding(medium-small), padding(x-x-small));
    }
  }

  &__heading-text {
    padding-right: padding(medium-small);

    @include respond(break(80)) {
      padding-right: 0;
      padding-bottom: padding(small);
    }
  }

  &__card {
    padding-right: padding(small);
  }

  &__image-link {
    display: block;

    @include link-image-background;

    &.focus-visible {
      outline-width: 0;
    }
  }

  &__image {
    @include fluid-scale(width height, 270px, 250px);
    @include link-image-fade;
    display: block;
    object-fit: cover;

    .m-instagram__image-link:hover &,
    .m-instagram__image-link.focus-visible & {
      @include link-image-hover-opacity;
    }
  }
}
