.m-sold-out {
  display: block;

  @include copy-bold;

  &__event-page {
    margin-bottom: 16px;
  }

  &__large {
    @include heading-secondary;
  }

  &__event-landing {
    margin-top: -16px;
    margin-bottom: 10px;
  }
}
