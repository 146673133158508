// Typography
// --------------------------------------------------------

body {
  font-family: $font-stack-base;
  font-size: 1em;
  line-height: $line-height-base;
  letter-spacing: $leading-base;
}

// Block-level elements
// --------------------------
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

h1 {
  @include heading-primary;
}

h2 {
  @include heading-secondary;
}

h3 {
  @include heading-tertiary;
}

h4 {
  @include heading-quaternary;
}

h5 {
  @include heading-quinary;
}

h6 {
  @include heading-senary;
}

p {
  @include copy-primary;
  margin: 0;
}

ul {
  @include list-unordered-primary;
}

ol {
  @include list-ordered-primary;
}

hr {}

// Inline elements
// --------------------------

b,
strong {}
