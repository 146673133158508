// Newsletter Signup
//
// Defines spacing, colors, and a heading, and includes a nested form module with an
// email input field and submit button.
//
// Markup: kss-markup/newsletter-signup.html
//
// Styleguide Modules.NewsletterSignup

/* stylelint-disable selector-max-id  */
.m-newsletter-signup {
  position: relative;
  display: block;
  padding-top: 72px;
  padding-bottom: 72px;
  background-color: palette(light-gray);

  &__heading {
    @include copy-bold;
    color: palette(neutral-60);
  }

  &__form {
    margin-top: 30px;
    color: palette(neutral-75);
    border-bottom: 1px solid palette(neutral-75);
  }

  &__field-group {
    width: 100%;
  }

  &__input-error:not(:empty) {
    @include copy-primary;
    position: absolute;
    bottom: 15px;
    padding: 0;
    margin-block-end: 1em;
    color: palette(alert);
    background-color: inherit;

    &::before {
      display: inline-block;
      min-width: 25px;
      min-height: 25px;
      margin-right: 7px;
      vertical-align: middle;
      content: '';
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 25 25'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Ccircle cx='12.5' cy='12.5' r='12.5' fill='%23E01E37'/%3E%3Cpath fill='%23FFF' d='M11.2912144 18.163071C11.1252703 17.9526954 11 17.6995716 11 17.3643171 11 17.0701196 11.1252703 16.7759388 11.3335126 16.5655633 11.7499972 16.1448122 12.5000028 16.1448122 12.9164874 16.5655633 13.1247297 16.7759388 13.25 17.070128 13.25 17.3643171 13.25 17.7012462 13.1247297 17.9526954 12.9164874 18.163071 12.7066166 18.416178 12.4154147 18.5 12.1242087 18.5 11.7906711 18.5 11.4994567 18.3734465 11.2912144 18.163071zM12.125 14.75C11.4998148 14.75 11 14.2452707 11 13.6139383L11 7.63606171C11 7.00472925 11.4998148 6.5 12.125 6.5 12.7501852 6.5 13.25 7.00472925 13.25 7.63606171L13.25 13.6122547C13.25 14.2452287 12.7501852 14.7499579 12.125 14.7499579L12.125 14.75z'/%3E%3C/g%3E%3C/svg%3E");
      background-repeat: no-repeat;
    }
  }

  output {
    display: none;

    &.error {
      position: absolute;
      bottom: 15px;
      display: block;
      padding: 0;
      margin-block-end: 1em;
      color: palette(alert);
      background-color: inherit;
    }

    &.success {
      position: absolute;
      top: 0;
      right: 0;
      display: block;
      width: 100%;
      height: 100%;
      font-size: 0;
      background-color: palette(light-gray);

      &::after {
        @include heading-secondary;
        display: block;
        height: 97px;
        padding-top: 140px;
        margin-top: 25px;
        text-align: center;
        content: "You're In!";
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='97' height='97' viewBox='0 0 97 97'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Ccircle cx='48.5' cy='48.5' r='48.5' fill='%23AFC9AD'/%3E%3Cpath fill='%23FFF' d='M66.6425747,34.473569 L42.8923366,63.5625311 C42.6557605,63.8551224 42.3588825,64 42.0620283,64 C41.7651741,64 41.4682723,63.8551224 41.23172,63.5625311 L29.3566009,49.01805 C28.881133,48.4357036 28.881133,47.5636019 29.3566009,46.9812409 C29.8320688,46.3988944 30.5441128,46.3988944 31.0195926,46.9812409 L42.0622658,60.5090627 L64.9824331,32.4367599 C65.457901,31.8544134 66.169945,31.8544134 66.6454247,32.4367599 C67.118577,33.0191063 67.118577,33.8940441 66.6431091,34.473569 L66.6425747,34.473569 Z'/%3E%3C/g%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: center;
      }
    }
  }
}
