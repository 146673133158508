.m-table {
  width: 100%;

  // &__header {}

  &__header-text {
    @include heading-quinary;
    display: block;
    margin-bottom: 14px;
    color: palette(neutral-75);
    text-align: left;
  }

  // &__body {}

  &__row {
    display: flex;
  }

  &__row-header {
    display: block;
    flex-basis: 37.5%;
    min-width: 140px;
    color: palette(neutral-75);
    text-align: left;
  }

  &__data {
    display: block;
    flex-basis: 62.5%;
    min-width: 100px;
    text-align: left;
  }

  &__footer {
    display: block;
    margin-top: padding(medium);
    text-align: left;
  }
}
