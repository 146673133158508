.m-header-primary {
  $logo-height-large: 62.5px;
  $logo-height-small: 31.5px;

  @mixin link-padding {
    @include fluid-scale(padding-bottom, 52px, 30px);
    @include fluid-scale(padding-right padding-left, 24px, 8px);
  }
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  font-size: 13px;
  color: palette(neutral-75);
  background-color: palette(white);
  transition:
    transform $duration-nav-submenu-short ease,
    background-color $duration-nav-submenu-long ease;
  transform: translateY(0);

  &--transparent:not(.headroom--not-top, :hover) {
    @include respond(break(60) + 1, min) {
      background-color: transparent;
    }
  }

  &--transparent-white:not(.headroom--not-top, :hover) {
    color: palette(white);
    background-color: transparent;
  }

  &--transparent:not(:hover),
  &--transparent-white:not(:hover) {
    transition:
      transform $duration-nav-submenu-short ease,
      background-color $duration-nav-submenu-long ease;
  }

  &.headroom--unpinned {
    transform: translateY(-100%);
  }

  &.headroom--pinned {
    transform: translateY(0);
  }

  &__wrapper {
    @include container-x-wide;
    @include fluid-scale(
      padding-right padding-left,
      $header-primary-padding-lateral-large,
      $header-primary-padding-lateral-small
    );
    @include fluid-scale(
      padding-top,
      $header-primary-padding-top-large,
      $header-primary-padding-top-small
    );
  }

  &__nav-wrapper {
    @include fluid-scale(padding-top, 18px, 0px);
  }

  &__link {
    @include link-padding;
    display: block;
  }

  &__logo {
    @include fluid-scale(width, 255px, 128px);
    @include fluid-scale(height, $logo-height-large, $logo-height-small);

    .m-header-primary--minimal & {
      @include fluid-scale(width, 180px, 128px);
      @include fluid-scale(
        height,
        $logo-height-large - 20px,
        $logo-height-small
      );
    }
  }

  &__logo-svg {
    width: auto;
    height: 100%;
  }

  &__icon {
    width: 1.846em;
    height: 1.846em;
  }

  &__toggle {
    @include link-padding;
    @include hover-recolor;
  }

  &__toggle--hamburger {
    display: none;

    @include respond(break(60)) {
      display: inline-block;
    }
  }
}
