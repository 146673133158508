.m-navigation-tabbed {
  @include container;

  // height: var(--waypoint-nav-height, auto);
  padding-top: padding(x-small);

  &--padded-top {
    padding-top: padding(medium-small);
  }

  // &--search {
  //   @include respond(break(90)) {
  //     display: none;
  //   }
  // }

  &.headroom {
    // haven't scrolled to module yet
    &--top {
    }

    // have scrolled to/past module
    &--not-top {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      padding-bottom: padding(medium);
      margin-left: 50%;
      background-color: palette(white);
      transform: translateX(-50%);
    }

    &--unpinned,
    &--pinned {
      transition: top 0.2s ease;
    }

    // user is scrolling down
    &--unpinned {
    }

    // user is scrolling up (so position below site header)
    &--pinned.headroom--not-top {
      top: 130px;
      top: var(--header-height, 130px);
    }
  }

  &__list {
    @include list-unstyled;

    &--desktop {
      display: flex;
      justify-content: center;

      @include respond(break(80)) {
        display: none;
      }
    }

    &--mobile {
      display: none;
      flex-direction: column;
      align-items: center;
      color: palette(white);
      background-color: palette(neutral-75);

      @include respond(break(80)) {
        display: flex;
      }
    }
  }

  &__mobile-wrapper {
    position: relative;
    display: none;
    flex-direction: column;
    align-items: center;

    @include respond(break(80)) {
      display: flex;
    }
  }

  &__item {
    text-align: center;
    border-bottom: 1px solid palette(neutral-25);

    @include respond(break(80)) {
      width: 100%;
      border-color: #4d4d4d;
    }
  }

  &__dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    box-shadow: 0px 21px 20px rgba(#000, 0.08);
    opacity: 0;
    transition:
      opacity $duration ease-out,
      max-height $duration $duration ease-out;

    &.is-open {
      overflow: auto;
      opacity: 1;
      transition:
        opacity $duration $duration ease-out,
        max-height $duration ease-out;
    }

    &.is-closed {
      //to prevent menu item links from overlaying page content when hidden
      .m-navigation-tabbed__item {
        height: 0;
      }
    }
  }

  &__link,
  &__toggle {
    @include fluid-scale(font-size, 19px, 14px, break(140), break(80));
    display: block;
    font-family: $font-stack-heading;
    font-weight: $medium;
    line-height: 1.455;
    letter-spacing: 0.023em;

    @include respond(break(80)) {
      font-size: 24px;
    }
  }

  &__toggle {
    @include button-unstyled;
    @include hover-recolor;
    padding-top: 5px;
  }

  &__toggle-border {
    padding-bottom: 5px;
    border-bottom: 2px solid palette(primary);
  }

  &__toggle-svg {
    transition: transform linear 0.25s;
  }

  &__toggle[aria-pressed='true'] &__toggle-svg {
    transform: rotate(180deg);
  }

  &__link {
    @include fluid-scale(
      padding-right padding-left,
      46px,
      10px,
      break(140),
      break(80)
    );
    @include fluid-scale(padding-top, padding(small), padding(x-small) + 4);
    @include fluid-scale(padding-bottom, padding(small), padding(x-small));
    margin-right: 10px;
    margin-left: 10px;

    @include respond(break(80) + 1, min) {
      @include hover-recolor;
      height: 100%;

      &--active {
        border-bottom: 2px solid palette(primary);
      }
    }

    @include respond(break(80)) {
      position: relative;
      width: 100%;
      margin-right: 0;
      margin-left: 0;
      color: #d0d0d0;
      transition: color 0.2s $timing;

      &--active,
      &:hover,
      &:focus {
        color: palette(white);
      }

      &--active {
        &::before {
          position: absolute;
          display: inline;
          color: palette(primary);
          content: '•';
          transform: translateX(-20px);
        }
      }
    }

    &--inactive {
      @include respond(break(80) + 1, min) {
        color: rgba(palette(neutral-75), 0.8);
      }
    }
  }
}
