.m-download-link {
  @include heading-quinary;
  display: inline-flex;
  justify-content: space-between;
  color: palette(neutral-75);

  &::before {
    display: inline;
    width: 1.393em;
    min-width: 28px;
    height: 1.393em;
    min-height: 28px;
    vertical-align: middle;
    content: '';
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='20' viewBox='0 0 18 20'%3E%3Cg fill-rule='evenodd'%3E%3Cpath d='M13.0279884,6.25350761 L9.66470998,3.04518965 L10.7601834,2 L16,7.00001791 L10.7601834,12 L9.66470998,10.9548104 L13.0287769,7.74574019 L2,7.74574019 L2,6.25350761 L13.0279884,6.25350761 Z' transform='rotate(90 9 7)'/%3E%3Cpath stroke='%232E2E30' stroke-linecap='square' d='M0.5,19.5 L17.3776501,19.5'/%3E%3C/g%3E%3C/svg%3E");
    background-repeat: no-repeat;
    transition: transform $duration ease-out;
  }

  &:hover {
    &::before {
      transform: translateY(3px);
    }
  }
}
