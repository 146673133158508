@use 'sass:color';

// Typography
// -----------------------------------

// Typography mixins and utility classes.

// These should be limited to `font` and other typographic properties only.

// Alignment
// --------------------------------------------------------
.t-align-center {
  text-align: center;
}

// Headings
// --------------------------------------------------------
@mixin proto-heading {
  font-family: $font-stack-heading;
  font-weight: $medium;
}

// Primary Heading
//
// Weight: 1
//
// Markup:
// <h1 class="t-heading-primary">A Primary Heading</h1>
//
// Styleguide Utility.Typography.headingPrimary

@mixin heading-primary {
  @include proto-heading;
  @include fluid-scale(font-size, 45px, 32px);
  line-height: 1.25;
  letter-spacing: 0.026em;
}

.t-heading-primary {
  @include heading-primary;
}

// Secondary Heading
//
// Weight: 2
//
// Markup:
// <h2 class="t-heading-secondary">A Secondary Heading</h2>
//
// Styleguide Utility.Typography.headingSecondary

@mixin heading-secondary {
  @include proto-heading;
  @include fluid-scale(font-size, 32px, 24px);
  line-height: 1.2;
  letter-spacing: 0.026em;
}

.t-heading-secondary {
  @include heading-secondary;
}

// Tertiary Heading
//
// Weight: 3
//
// Markup:
// <h3 class="t-heading-tertiary">A Tertiary Heading</h3>
//
// Styleguide Utility.Typography.headingTertiary

@mixin heading-tertiary {
  @include proto-heading;
  @include fluid-scale(font-size, 22px, 18px);
  line-height: 1.455;
  letter-spacing: 0.016em;
}

.t-heading-tertiary {
  @include heading-tertiary;
}

// Quaternary Heading
//
// Weight: 4
//
// Markup:
// <h4 class="t-heading-quaternary">A Quaternary Heading</h4>
//
// Styleguide Utility.Typography.headingQuaternary

@mixin heading-quaternary {
  @include proto-heading;
  font-size: 20px;
  line-height: 1.45;
  letter-spacing: 0.023em;
}

.t-heading-quaternary {
  @include heading-quaternary;
}

// Quinary Heading
//
// Weight: 5
//
// Markup:
// <h5 class="t-heading-quinary">A Quinary Heading</h5>
//
// Styleguide Utility.Typography.headingQuinary

@mixin heading-quinary($small: false) {
  @if $small {
    font-size: 13px;
  } @else {
    @include fluid-scale(font-size, 14px, 13px);
  }
  font-family: $font-stack-base;
  font-weight: $semibold;
  line-height: 1.214;
  text-transform: uppercase;
  letter-spacing: 0.066em;
}

.t-heading-quinary {
  @include heading-quinary;
}

// Senary Heading
//
// Weight: 6
//
// Markup:
// <h6 class="t-heading-senary">A Senary Heading</h6>
//
// Styleguide Utility.Typography.headingSenary

@mixin heading-senary {
  @include fluid-scale(font-size, 16px, 14px);
  font-family: $font-stack-base;
  font-weight: $bold;
  line-height: 1.875;
}

.t-heading-senary {
  @include heading-senary;
}

// Copy
// --------------------------------------------------------

@mixin proto-copy {
  @include fluid-scale(font-size, 16px, 14px);
  font-family: $font-stack-base;
  line-height: 1.75;
}

// Primary Copy
//
// Weight: 7
//
// Markup:
// <p class="t-copy-primary">Here is some standard copy. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
//
// Styleguide Utility.Typography.copyPrimary

@mixin copy-primary {
  @include proto-copy;
  font-weight: $regular;
}

.t-copy-primary {
  @include copy-primary;
}

@mixin copy-bold {
  @include proto-copy;
  font-weight: $semibold;
}

.t-copy-bold {
  @include copy-bold;
}

// Small Copy
//
// Weight: 8
//
// Markup:
// <p class="t-copy-small">Here is some smaller copy. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
//
// Styleguide Utility.Typography.copySmall

@mixin copy-small {
  font-family: $font-stack-base;
  font-size: 12px;
  font-weight: $regular;
  line-height: 1.667;
}

.t-copy-small {
  @include copy-small;
}

@mixin copy-artwork-caption {
  @include copy-small;
  line-height: 1.167;

  @each $name, $value in $palette {
    .a-bg-#{$name + ""} & {
      @if color.lightness($value) > 99% {
        color: palette('neutral-50');
      } @else if color.lightness($value) > 95% {
        color: palette('neutral-60');
      } @else if color.lightness($value) > 70% {
        color: palette('neutral-65');
      } @else {
        color: inherit;
      }
    }
  }
}

// palette('neutral-75') is the base color for RTE

.t-copy-artwork-caption {
  @include copy-artwork-caption;
}

// Links
// --------------------------------------------------------

@mixin link-underlined {
  display: inline;
  background-image:
    linear-gradient(
      to bottom,
      palette(neutral-75) 33%,
      transparent 33%
    );
  background-repeat: repeat-x;
  background-position: bottom;
  background-size: 1px 4px;
}

@mixin simple-underline {
  text-decoration: underline;
}

// Lists
// --------------------------------------------------------
@mixin list-unstyled {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.t-list-unstyled {
  @include list-unstyled;
}

// Primary Unordered List
//
// Weight: 9
//
// Markup:
// <ul class="t-list-unordered-primary">
//   <li>Milk</li>
//   <li>Eggs</li>
//   <li>Cookies</li>
//   <li>Broccoli</li>
// </ul>
//
// Styleguide Utility.Typography.listUnorderedPrimary

@mixin list-unordered-primary {
  padding: 0;
  margin: 0 0 0 1em;
  font-weight: $regular;
}

.t-list-unordered-primary {
  @include list-unordered-primary;
}

// Primary Ordered List
//
// Weight: 10
//
// Markup:
// <ol class="t-list-ordered-primary">
//   <li>Milk</li>
//   <li>Eggs</li>
//   <li>Cookies</li>
//   <li>Broccoli</li>
// </ol>
//
// Styleguide Utility.Typography.listOrderedPrimary

@mixin list-ordered-primary {
  padding: 0;
  margin: 0 0 0 1em;
  font-weight: $regular;
}

.t-list-ordered-primary {
  @include list-ordered-primary;
}
