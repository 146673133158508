// Link List
//
// A vertical of text links with large text and an optional arrow icon. Has `&--dark` variant for theming colors.
//
// Markup: kss-markup/link-list.html
//
// Styleguide Modules.LinkList

.m-link-list {
  @include list-unstyled;

  &__item {
    & + & {
      border-top: 1px solid palette(neutral-10);

      .m-link-list--dark & {
        border-color: palette(neutral-65);
      }
    }
  }

  &__link {
    @include hover-recolor;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 6px;
    padding-bottom: 2px;
    cursor: pointer;

    .m-link-list--dark & {
      &:hover {
        color: rgba(palette(white), $hover-opacity-60);
      }
    }
  }

  // &__nested-list {}

  &__nested-item {
    list-style-type: disc;

    &:last-child {
      margin-bottom: 8px;
    }
  }

  &__nested-link {
    @include hover-recolor;
    display: block;
  }

  &__text {
    font-family: $font-stack-heading;
    font-size: 22px;
    font-weight: $medium;
    letter-spacing: 0.026em;

    &--small {
      font-size: 18px;
    }
  }

  &__icon {
    width: 14px;
    height: 14px;
    margin-right: 8px;
  }
}
