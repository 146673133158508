// Slideshow Callout

// Module is made up of other modules (slider, content-rte).
// Styles here primarily handles layout styling & pagination.
// Grid layout similar to `column-grid` module but composed here
// due to significant differences on mobile

@use 'sass:math';

.m-slideshow-callout {
  /* stylelint-disable property-no-vendor-prefix */
  $column-padding: padding(small);
  $wrapper-base-width: container-width(wide);
  $mobile-breakpoint: break(80);
  $columns: 12;
  $column-width: math.div($wrapper-base-width, $columns);
  $grid-gap: 4.514vw;

  @mixin column-margin($direction) {
    @include respond($mobile-breakpoint + 1, min) {
      @if $direction == 'left' {
        padding-right: padding(small);
      } @else {
        order: 1;
        padding-left: padding(small);
      }
      margin-#{$direction}: -$column-padding;
    }

    @include respond($wrapper-base-width, min) {
      margin-#{$direction}:
        calc(
          (100vw - #{container-width(wide)}) / -2
        ); // fallback
      margin-#{$direction}:
        calc(
          ((var(--vw, 1vw) * 100) - #{container-width(wide)}) / -2
        );
    }

    @include respond(container-width(max), min) {
      margin-#{$direction}: math.div(container-width(max) - container-width(wide), -2);
    }
  }

  @include container-wide;
  display: grid;
  grid-template: auto / repeat($columns, 1fr);
  grid-gap: $grid-gap;
  padding-right: 0;
  padding-left: 0;

  @include respond($mobile-breakpoint) {
    grid-template: auto / repeat($columns, 1fr);
    -ms-grid-rows: auto auto;
    -ms-grid-columns: 100%;
    grid-gap: 0px;

    &__slideshow-column,
    &__text-column {
      grid-column: span $columns;
    }

    &__slideshow-column {
      -ms-grid-row: 1;
      -ms-grid-column: 1;
    }

    &__text-column {
      -ms-grid-row: 2;
      -ms-grid-column: 1;
    }
  }

  @include respond($mobile-breakpoint + 1, min) {
    -ms-grid-columns:
      1fr $grid-gap 1fr $grid-gap 1fr $grid-gap 1fr $grid-gap
      1fr $grid-gap 1fr $grid-gap 1fr $grid-gap 1fr $grid-gap 1fr $grid-gap 1fr
      $grid-gap 1fr $grid-gap 1fr;
    padding-right: $column-padding;
    padding-left: $column-padding;

    &__slideshow-column {
      -ms-grid-row: 1;
      -ms-grid-column: 1;
      -ms-grid-column-span: 13;
      grid-column: span $columns * 0.5 + 1;
    }

    &__text-column {
      -ms-grid-row: 1;
      -ms-grid-column: 15;
      -ms-grid-column-span: 9;
      grid-column: span $columns * 0.5 - 1;
    }
  }

  &__slideshow-column {
    &--left {
      @include column-margin(left);
    }

    &--right {
      @include column-margin(right);
    }
  }

  &__text-column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__image {
    &--fixed {
      height: var(--slideshow-callout-image-height, auto);
      max-height: var(--slideshow-callout-image-max-height, auto);
      object-fit: cover;
    }
  }

  &__caption {
    @include respond($mobile-breakpoint) {
      padding-right: $column-padding;
      padding-bottom: padding(medium);
      padding-left: $column-padding;
    }

    @include respond($mobile-breakpoint + 1, min) {
      margin-top: 80px;
    }
  }

  &__pagination {
    line-height: 0;

    @include respond($mobile-breakpoint) {
      order: -1;
      margin-bottom: padding(small);
    }

    @include respond($mobile-breakpoint + 1, min) {
      margin-top: 100px;
    }
  }

  &__prev-next {
    @include prev-next-button;
    @include fluid-scale(width, 93px, 66px);
    @include fluid-scale(height, 102px, 72px);

    @include respond($mobile-breakpoint) {
      width: 50%;
      height: 56px;

      &.previous {
        border-right: 1px solid rgba(palette(white), 0.15);
      }
    }
  }
}
