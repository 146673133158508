.m-page-heading {
  /* stylelint-disable property-no-vendor-prefix */
  @include container-x-wide;
  @include fluid-scale(
    padding-right padding-left,
    $header-primary-padding-lateral-large,
    padding(small)
  );
  @include fluid-scale(
    padding-top,
    $header-primary-padding-top-large,
    $header-primary-padding-top-small
  );
  display: grid;
  grid-template:
    'back-link title .' auto / minmax(150px, 1fr) minmax(
      auto,
      container-width(medium-wide)
    )
    minmax(150px, 1fr);

  @include respond(break(90)) {
    grid-template:
      'back-link' auto
      'title' auto
      'mobile-subheading' / auto;
  }

  > * {
    align-self: baseline;

    @include respond(break(90)) {
      align-self: initial;
    }
  }

  &--padded-top {
    @include fluid-scale(padding-top, 100px, padding(small));
  }

  &--bottom-padding {
    @include fluid-scale(padding-bottom, padding(medium-large), padding(small));
  }

  &__title {
    grid-area: title;
    text-align: center;

    @include respond(break(90) + 1, min) {
      padding-right: padding(x-x-small);
      padding-left: padding(x-x-small);
    }

    &--left {
      @include respond(break(90)) {
        text-align: left;
      }
    }
  }

  &__mobile-subheading {
    grid-area: mobile-subheading;
    padding-top: padding(medium);
    font-family: $font-stack-heading;
    font-size: 16px;
    font-weight: $medium;
    line-height: 1.5;
    letter-spacing: 0.032em;

    @include respond(break(90) + 1, min) {
      display: none;
    }
  }

  &__back-link {
    @include hover-recolor;
    @include heading-quinary;
    grid-area: back-link;
    -ms-grid-row: 1;
    -ms-grid-column: 1;

    @include respond(break(90)) {
      margin-bottom: 53px;
      text-align: center;
    }

    @include respond(break(90) + 1, min) {
      margin-bottom: 53px;
      text-align: center;
      transform:
        translateY(
          -9px
        ); // to vertically align with middle of first line of heading
    }

    &--desktop {
      @include respond(break(90)) {
        display: none;
      }
    }
  }

  &__back-link-caret {
    color: palette(neutral-45);
    transition: color 0.2s $timing;

    .m-page-heading__back-link:hover &,
    .m-page-heading__back-link.focus-visible & {
      color: inherit;
    }
  }
}
