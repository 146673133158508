.m-location-slider {
  @include fluid-scale(margin-right, 94px, padding(small));
  @include fluid-scale(width, 1050px, 400px, break(110), break(90));
  width: 1050px;

  @include respond(break(140)) {
    width: 85%;
  }

  @include respond(break(80)) {
    width: 100%;
  }

  &__hero,
  &__hover-text {
    transition: opacity 0.5s ease;
  }

  &__hero {
    @include fluid-scale(height, 418px, 200px);
    width: 100%;
    object-fit: cover;
    backface-visibility: hidden; // prevents jumping on opacity change
  }

  &__figure {
    position: relative;
    order: -1;
    font-size: 0;
    background-color: palette(pink);

    @include respond(break(80)) {
      margin-right: padding(small);
    }
  }

  &__hover-text {
    @include fluid-scale(max-width, 275px, 170px);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    max-width: 280px;
    max-height: 30px;
    margin: auto;
    opacity: 0;
  }

  &__hover-accent {
    @include fluid-scale(width, 100px, 40px);
    @include fluid-scale(margin-bottom, 7px, 5px);
    @include fluid-scale(margin-left, 20px, 10px);
    display: inline-block;
    height: 3px;
    background-color: palette(neutral-60);
  }

  &__hover-heading {
    display: inline-block;
  }

  &__hero-link {
    &:hover {
      .m-location-slider__hero {
        opacity: 0.333;
      }

      .m-location-slider__hover-text {
        opacity: 1;
      }
    }
  }


  &__info-columns {
    @include fluid-scale(padding-left padding-right, 160px, padding(medium-small));
    @include fluid-scale(padding-top, 53px, padding(small));
    display: flex;

    @include respond(break(80)) {
      flex-direction: column;
    }
  }

  &__address {
    @include fluid-scale(padding-top, 40px, padding(x-x-small));
    display: block;
  }

  &__col-left {
    @include fluid-scale(padding-right, 150px, padding(medium), break(110), break(90));

    @include respond(break(80)) {
      padding-right: 0;
    }
  }

  &__col-right {
    min-width: 280px;

    @include respond(break(80)) {
      display: block;
      padding-top: padding(x-small);
    }
  }

  &__day-heading {
    @include copy-primary;
    min-width: 140px;

    @include respond(break(60)) {
      min-width: 100px;
    }
  }

  &__hours {
    @include copy-primary;

    @include respond(break(60)) {
      max-width: 90px;
    }
  }
}
