.m-sponsor-list {
  &__heading {
    @include fluid-scale(
      padding-bottom,
      padding(medium-large),
      padding(x-small)
    );
    max-width: 627px;
    color: palette(neutral-75);
  }

  &__list-container {
    @include list-unstyled;
  }

  &__logo-container,
  &__description-container {
    display: flex;
    align-items: center;

    @include respond(break(60)) {
      justify-content: center;
    }
  }

  &__description-container {
    flex: 1;
    width: 100%;

    @include respond(break(60)) {
      flex: auto;
    }
  }

  &__sponsor-container {
    display: flex;
    min-height: 160px;

    @include respond(break(60)) {
      flex-direction: column;
      min-height: 0;
    }

    &:not(:last-child) {
      .m-sponsor-list__description-container {
        border-bottom: 1px solid palette(neutral-30);

        @include respond(break(60)) {
          padding-bottom: padding(x-small);
        }
      }
    }
  }

  &__logo,
  &__name {
    @include fluid-scale(margin-right, 80px, padding(small));

    @include respond(break(60)) {
      margin: padding(x-small);
    }
  }

  &__logo {
    max-width: 185px;
  }

  &__name {
    width: 185px;
    color: palette(neutral-75);
  }

  &__description {
    padding-top: padding(x-small);
    padding-bottom: padding(x-small);
    color: palette(neutral-60);
  }

  &__name,
  &__description {
    @include respond(break(60)) {
      padding-top: 0;
      padding-bottom: 0;
      text-align: center;
    }
  }
}
