.m-location-landing {
  &__heading {
    @include hover-recolor;
    @include fluid-scale(padding-bottom, padding(medium-large), padding(small));
    padding-right: padding(small);
    padding-left: padding(small);

    @include respond(break(60)) {
      display: none;
    }
  }

  &__heading-mobile {
    padding-top: padding(medium-large);
    padding-bottom: padding(small);
  }

  &__index {
    color: palette(neutral-25);
  }

  &__flex-container {
    display: flex;

    @include respond(break(60)) {
      display: block;
    }
  }

  &__info-container {
    @include fluid-scale(padding-left, 170px, padding(small));
    width: 62vw;
    max-width: 960px;
    padding-right: 30px;

    @include respond(break(130)) {
      width: 84vw;
    }

    @include respond(break(100)) {
      width: 60vw;
    }

    @include respond(break(60)) {
      width: 100%;
    }
  }

  &__info-container-inner {
    display: flex;

    @include respond(break(100)) {
      flex-direction: column;
    }

    &--desktop {
      @include respond(break(60)) {
        display: none;
      }
    }

    &--mobile {
      display: none;

      @include respond(break(60)) {
        display: block;
      }
    }
  }

  &__info-column {
    flex: 1;

    @include respond(break(110)) {
      flex-basis: auto;
      width: 100%;
    }

    &:first-child {
      padding-right: 50px;
      border-right: 1px solid palette(neutral-40);

      @include respond(break(140)) {
        padding-right: padding(x-small);
      }

      @include respond(break(100)) {
        padding-right: 0;
        border-right: none;
      }
    }

    &:last-child {
      padding-left: 49px;

      @include respond(break(140)) {
        padding-left: 30px;
      }

      @include respond(break(100)) {
        padding-left: 0;
      }
    }
  }

  // Overrides the RTE styles for the prepend text, which appears differently
  // on the location landing and detail pages and is a special case
  &__info-prepend {
    padding-bottom: padding(small);
    margin-bottom: padding(small);
    border-bottom: 1px solid palette(neutral-40);

    @include respond(break(100)) {
      padding-top: padding(small);
      margin-bottom: 0;
      border-bottom: none;
    }

    h5 {
      @include copy-primary;
      font-weight: $semibold;
      text-transform: capitalize;
      letter-spacing: normal;

      @include respond(break(100)) {
        margin-bottom: 0;
      }
    }

    p {
      @include heading-tertiary;
      font-weight: $medium;
    }
  }

  &__image-container {
    width: 585px;
    height: 390px;

    @include respond(break(140)) {
      width: 40.625vw;
      height: 27.083vw;
    }

    @include respond(break(60)) {
      width: 100%;
      height: 86.133vw;
      max-height: 420px;
    }

    &--desktop {
      @include respond(break(60)) {
        display: none;
      }
    }

    &--mobile {
      display: none;

      @include respond(break(60)) {
        display: block;
      }
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__button-desktop {
    position: relative;
    top: -50%;
    display: block;
    width: 210px;
    margin: 0 auto;
    transform: translateY(-50%);
    backface-visibility: hidden;

    @include respond(break(60)) {
      display: none;
    }
  }

  &__button-mobile {
    margin-top: padding(medium);
  }

  &__info-heading-label {
    @include copy-bold;
  }

  &__info-heading {
    @include fluid-scale(padding-bottom, 32px, padding(x-x-small));

    &--top {
      @include respond(break(60)) {
        padding-top: padding(x-small);
      }
    }

    &--top-padding {
      padding-top: 68px;
    }

    &--interior {
      @include respond(break(100)) {
        &:first-child {
          padding-top: padding(medium-small);
        }
      }
    }
  }

  &__schedule-table-heading,
  &__schedule-table-data {
    font-weight: $semibold;
  }

  &__schedule-table-heading {
    margin-right: 23px;
  }

  &__schedule-table-footer {
    margin-top: padding(medium-small);
  }

  &__address-top {
    display: block;
  }

  &__accordion-toggle {
    padding-right: 4px;
    border-bottom: 1px solid transparent;
    transition: border-color $duration $timing;

    &:hover {
      border-color: inherit;
    }
  }
}
