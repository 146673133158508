.vertical-spacer {
  display: block;

  @include respond(break(110)) {
    display: none;
  }
}

.m-vertical-spacer {
  display: none;

  @include respond(break(110)) {
    display: block;
  }
}
