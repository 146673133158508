// State
// --------------------

// Mixins and classes for setting styles based on component state.
// Classes are prepended with `is-` or `has-`.

@mixin has-scroll-lock {
  &::after {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -25;
    width: 100%;
    height: 100%;
    content: '';
    transition: background-color 0.2s linear;
  }

  &.has-scroll-lock {
    position: absolute;
    width: 100%;
    overflow: hidden;

    &::after {
      background-color: rgba(palette(white), 0.9);
    }
  }
}

// apply `has-scroll-lock` mixin to body for overlays
body {
  @include has-scroll-lock;
}

// React CSS Transition classes
.react-fade--enter {
  opacity: 0.01;
}

.react-fade--enter.react-fade--enter-active {
  opacity: 1;
  transition: opacity 0.2s ease-in;
}

.react-fade--leave {
  opacity: 1;
}

.react-fade--leave.react-fade--leave-active {
  opacity: 0.01;
  transition: opacity 0.2s ease-in;
}

.react-appear--enter {
  opacity: 0.01;
}

.react-appear--enter-done {
  opacity: 1;
  transition: opacity 0.2s ease-in;
}

.react-appear--appear.react-appear--appear-active {
  opacity: 0.01;
  transition: none;
}
