.m-heading-with-link {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  &--break-80 {
    @include respond(break(80)) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &--break-60 {
    @include respond(break(60)) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &--padding-large {
    @include fluid-scale(margin-bottom, padding(x-large), padding(small));
  }

  &--padding-small {
    @include fluid-scale(margin-bottom, padding(medium-large), padding(small));
  }

  &__button {
    padding-bottom: 6px;
  }
}
