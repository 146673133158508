// Pagination
//
// Styleguide Modules.Pagination

.m-pagination {
  @include fluid-scale(padding-top, padding(large), padding(x-small));
  display: flex;
  align-items: center;
  justify-content: center;
  color: palette(primary);

  &__link {
    @include hover-fade($hover-opacity-60);
    display: flex;
    align-items: center;
    justify-content: center;
    color: palette(primary);

    &:last-child {
      @include fluid-scale(padding-left, 110px, padding(small));
    }

    &:first-child {
      @include fluid-scale(padding-right, 110px, padding(small));

      &:not(:last-child) {
        border-right: 1px solid palette(neutral-30);
      }
    }
  }

  &__back-arrow {
    order: -1;
    margin-right: 16px;
    transform: rotateY(180deg);
  }

  &__next-arrow {
    margin-left: 16px;
  }
}
