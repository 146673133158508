// @font-face-specific mixins
// -----
@mixin embedFont($font-name, $font-filepath-and-name, $font-weight, $font-style) {
  @font-face {
    font-family: $font-name;
    font-style: $font-style;
    font-weight: $font-weight;
    src:
      url('../fonts/#{$font-filepath-and-name}.woff2') format('woff2'),
      url('../fonts/#{$font-filepath-and-name}.woff') format('woff');
  }
}

// @font-face declarations
// --------------------------
// Do your @font-face embedding here, in the privacy of your own partial
// `embedFont` must be used once per combination of weight and style

@include embedFont('ITCAvantGardeGothic', 'AvantGardeGothic-Medium', $medium, normal);
@include embedFont('ITCAvantGardeGothic', 'AvantGardeGothic-MediumOblique', $medium, italic);

// @include embedFont('ITCAvantGardeGothic', 'AvantGardeGothic-Bold', $bold, normal);
// @include embedFont('ITCAvantGardeGothic', 'AvantGardeGothic-BoldOblique', $bold, italic);

@include embedFont('Metropolis', 'Metropolis-Regular', $regular, normal);
@include embedFont('Metropolis', 'Metropolis-RegularItalic', $regular, italic);
@include embedFont('Metropolis', 'Metropolis-SemiBold', $semibold, normal);
@include embedFont('Metropolis', 'Metropolis-SemiBoldItalic', $semibold, italic);
@include embedFont('Metropolis', 'Metropolis-Bold', $bold, normal);
@include embedFont('Metropolis', 'Metropolis-BoldItalic', $bold, italic);
