.m-entity-list-filters {
  $active-indicator-padding: 30px;

  @include fluid-scale(margin-bottom, 100px, 50px);
  @include fluid-scale(padding-right padding-left, 80px, 45px);
  @include fluid-scale(font-size, 38px, 24px);
  position: relative;
  font-family: $font-stack-heading;
  line-height: 1.417;
  letter-spacing: 0.026em;

  &__inner {
    text-align: center;
  }

  &__small-text {
    @include respond(break(70)) {
      @include heading-quinary(small);
      position: relative;
      z-index: 0;
      display: block;
      margin-bottom: 12px;
      transition: z-index 0s 0.3s linear;

      &:not(:first-child) {
        margin-top: 42px;
      }

      &--is-active {
        // z-index changes … is set in z-stack file
        transition-delay: 0s;
      }
    }
  }

  &__filter {
    position: relative;
  }

  &__current {
    @include hover-recolor;
  }

  &__current-text {
    padding-bottom: 6px;
    line-height: 1.5;
    box-shadow:
      inset 0 -1px palette(white),
      inset 0 -3px palette(primary);

    &--tall {
      line-height: 1.75;
    }
  }

  &__option-listbox {
    @include fluid-scale(width, container-width(wide), 515px);
    position: absolute;
    top: 100%;
    left: 50%;
    margin-top: -60px;
    overflow: auto;
    transform: translateX(-50%);

    @include respond(break(70) + 1, min) {
      max-width: 75vw;
      margin-top: 0;
      transform: none;

      &--left {
        left: -$active-indicator-padding;
        padding-left: $active-indicator-padding;
        text-align: left;
      }

      &--right {
        right: -$active-indicator-padding;
        left: auto;
        padding-right: $active-indicator-padding;
        text-align: right;
      }
    }
  }

  &__option-list {
    @include list-unstyled;
    font-weight: inherit;

    @include respond(break(70)) {
      max-width: 80vw;
      margin: 0 auto;
    }
  }

  &__option {
    @include fluid-scale(font-size, 32px, 24px);
    margin-top: 1.25em;

    &:last-child {
      margin-bottom: 1.25em;
    }
  }

  &__button,
  &__close-button,
  &__reset-button {
    @include button-unstyled;
    @include hover-recolor;
  }

  &__button {
    position: relative;

    &--right {
      @include respond(break(70) + 1, min) {
        text-align: right;
      }
    }

    &--is-selected::after {
      @include fluid-scale(left, -$active-indicator-padding, -20px, break(70));
      position: absolute;
      top: 0;
      color: palette(primary);
      content: '•';

      .m-entity-list-filters__option-listbox--right & {
        @include respond(break(70) + 1, min) {
          right: -$active-indicator-padding;
          left: auto;
        }
      }
    }
  }

  &__reset-button,
  &__close-button--desktop {
    @include fluid-scale(font-size, 22px, 12px);
    position: absolute;
    right: 0;
    opacity: 0;
    transition: opacity 0.2s linear, top 0.2s 0.1s linear;

    &::before {
      display: inline;
      content: '—';
    }

    @include respond(break(70)) {
      right: 2.5%;
    }
  }

  &__reset-button--filters-closed,
  &__close-button--desktop {
    top: 50%;
    transform: translateY(-50%);
  }

  &__reset-button {
    &--is-active {
      opacity: 1;
    }

    @include respond(break(70)) {
      top: -2%;
      transform: none;
    }

    @include respond(break(70) + 1, min) {
      &:not(.m-entity-list-filters__reset-button--filters-closed) {
        top: 100%;
      }
    }
  }

  &__close-button--desktop {
    &.m-entity-list-filters__close-button--is-active {
      opacity: 1;
    }

    @include respond(break(70)) {
      display: none;
    }
  }

  &__close-button--mobile {
    display: none;
    margin: 1.25em auto;

    @include respond(break(70)) {
      display: block;
    }
  }

  &__close-text {
    &::before {
      display: inline;
      content: '—';
    }
  }

  &__close-icon {
    width: 24px;
    height: 24px;
    fill: currentcolor;
  }
}
