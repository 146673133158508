// Slider
//
// A container that uses Flickity to render a touch-ready, responsive carousel.
// Can nest various modules as children, such as cards.
//
// Markup: kss-markup/slider.html
//
// Styleguide Modules.Slider

@use 'sass:math';

.m-slider {
  $gutter: 15px;
  $gutter-wide: 80px;
  width: 100%;

  &--cards {
    // center slider buttons on images in card-style layout, rather than halfway down
    .flickity-prev-next-button {
      @include fluid-scale(top, 210px, 110px);
    }
  }

  &__child {
    .flickity-resize & {
      min-height: 100%;
    }

    & + & {
      margin-left: $gutter;
    }

    // pad end of slider in case using `contain` option in Flickity
    .m-slider--contained &:last-child {
      padding-right: $gutter * 2;
    }
  }

  &--wide {
    max-width: container-width(wide);
    padding-right: 0;
    padding-left: padding(small);
    margin: 0 auto;

    @include respond(container-width(wide) + 1, min) {
      max-width:
        calc(
          #{container-width(wide)} + (100vw - #{container-width(wide)}) / 2
        ); // fallback
      max-width:
        calc(
          #{container-width(wide)} + (
            (var(--vw, 1vw) * 100) - #{container-width(wide)}
          ) / 2
        );
      padding-left: 0;
      margin-right: 0;

      // push previous button to left edge of container in wide slider
      &.m-slider .flickity-prev-next-button.previous {
        left: calc((100vw - #{container-width(wide)}) / -2); // fallback
        left: calc(((var(--vw, 1vw) * 100) - #{container-width(wide)}) / -2);
      }
    }

    &.m-slider--full {
      margin-right: auto;

      @include respond(container-width(wide)) {
        padding-left: 0;
      }
    }

    @include respond(container-width(max), min) {
      /* stylelint-disable-next-line max-line-length */
      max-width: container-width(wide) + ((container-width(max) - container-width(wide)) * 0.5);

      // push previous button to left edge of container in wide slider
      &.m-slider .flickity-prev-next-button.previous {
        left: math.div(container-width(max) - container-width(wide), -2);
      }
    }

    .m-slider__child + .m-slider__child {
      @include fluid-scale(margin-left, padding(small), $gutter);
      margin-left: padding(small);
    }

    &.m-slider--gutter-wide {
      .m-slider__child + .m-slider__child {
        @include fluid-scale(margin-left, $gutter-wide, $gutter);
      }
    }
  }

  .flickity-prev-next-button {
    @include prev-next-button;
    @include fluid-scale(width height, 102px, 72px);
    position: absolute;

    &.previous {
      left: 0;
    }

    &.next {
      right: 0;
    }
  }
}
