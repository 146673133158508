.m-page-404 {
  @include fluid-scale(height, 604px, 570px);
  position: relative;
  background: linear-gradient(palette(off-white) 50%, palette(peach-light) 50%);

  &__container,
  &__circle {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__message {
    position: absolute;
    top: 194px;

    //additional positioning to make centered on IE11
    left: 50%;
    width: 270px;
    margin-left: -135px;
    text-align: center;
  }

  &__circle {
    @include fluid-scale(width height, 452px, 250px, break(110), break(40));
    background: linear-gradient(palette(pink) 50%, palette(peach-bright) 50%);
    border-radius: 50%;
  }
}
