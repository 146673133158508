// Simple Location
//
// Basic presentation of information about one museum location.
//
// Markup:
// <div class="m-location-simple a-bg-neutral-75" itemscope itemtype="http://schema.org/Organization">
//   <div class="m-location-simple__name" itemprop="name">Palm Springs <br> Art Museum</div>
//   <div class="m-location-simple__address" itemprop="address" itemscope itemtype="http://schema.org/PostalAddress">
//     <span class="m-location-simple__street" itemprop="streetAddress">101 Museum Drive</span>
//     <span class="m-location-simple__city-state" itemprop="addressLocality">Palm Springs, CA</span>
//     <span class="m-location-simple__zip" itemprop="postalCode">92262</span>
//   </div>
//   <div class="m-location-simple__phone" itemprop="telephone">760-322-4800</div>
// </div>
//
// Styleguide Modules.LocationSimple

.m-location-simple {
  font-size: 15px;
  color: palette(white);

  &__name {
    display: block;
    margin-bottom: 8px;
    font-size: 16px;
    line-height: 1.563;

    &:hover {
      text-decoration: underline;
    }
  }

  &__address {
    color: rgba(palette(white), 0.5);
  }

  &__street {
    display: block;
  }

  // &__city-state {}

  // &__zip {}

  &__phone {
    color: rgba(palette(white), 0.5);
  }
}
