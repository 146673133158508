/*! Flickity v2.1.2
https://flickity.metafizzy.co
---------------------------------------------- */

.flickity-enabled {
  position: relative;
}

.flickity-enabled:focus {
  outline: none;
}

.flickity-viewport {
  position: relative;
  height: 100%;
  overflow: hidden;
  transition: height 0.5s ease;
}

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
}

/* draggable */

.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  /* stylelint-disable-next-line property-no-unknown */
  tap-highlight-color: transparent;
  user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: grab;
  cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: grabbing;
}

/* ---- flickity-cell ---- */
.flickity-cell {
  position: absolute;
  left: 0;
}

/* ---- flickity-button ---- */

// Disable for full cic styling
// .flickity-button {
//   position: absolute;
//   background: hsla(0, 0%, 100%, 0.75);
//   border: none;
//   color: #333;
// }
//
// .flickity-button:hover {
//   background: white;
//   cursor: pointer;
// }
//
// .flickity-button:focus {
//   outline: none;
//   box-shadow: 0 0 0 5px #19F;
// }
//
// .flickity-button:active {
//   opacity: 0.6;
// }
//
// .flickity-button:disabled {
//   opacity: 0.3;
//   cursor: auto;
//   /* prevent disabled button from capturing pointer up event. #716 */
//   pointer-events: none;
// }
//
// .flickity-button-icon {
//   fill: #333;
// }

/* ---- previous/next buttons ---- */

.flickity-prev-next-button {
  top: 50%;
  width: 44px;
  height: 44px;
  border-radius: 50%;

  /* vertically center */
  transform: translateY(-50%);
}

.flickity-prev-next-button.previous {
  left: 10px;
}

.flickity-prev-next-button.next {
  right: 10px;
}

/* right to left */
.flickity-rtl .flickity-prev-next-button.previous {
  right: 10px;
  left: auto;
}

.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 10px;
}

.flickity-prev-next-button .flickity-button-icon {
  position: absolute;
  top: 20%;
  left: 20%;
  width: 60%;
  height: 60%;
}

/* ---- page dots ---- */

.flickity-page-dots {
  position: absolute;
  bottom: -25px;
  width: 100%;
  padding: 0;
  margin: 0;
  line-height: 1;
  text-align: center;
  list-style: none;
}

.flickity-rtl .flickity-page-dots {
  direction: rtl;
}

.flickity-page-dots .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  cursor: pointer;
  background: #333;
  border-radius: 50%;
  opacity: 0.25;
}

.flickity-page-dots .dot.is-selected {
  opacity: 1;
}
