// Arrow Link
//
// Markup:
// <a href="#" class="m-arrow-link">Public Tours</a>
//
// Styleguide Modules.ArrowLink

.m-arrow-link {
  @include heading-tertiary;
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  color: palette(neutral-75);

  &::after {
    display: inline;
    width: 1.393em;
    min-width: 18px;
    height: 1.393em;
    min-height: 18px;
    margin-left: 4em;
    vertical-align: middle;
    content: '';
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cpath d='M22.905 14.665L17.14 8.928l1.878-1.869L28 16l-8.983 8.941-1.878-1.869 5.767-5.738H4v-2.669z' fill='%232E2E30' /%3E%3C/svg%3E");
    background-repeat: no-repeat;
    transition: transform $duration ease-out;
  }

  &:hover {
    &::after {
      transform: translateX(5px);
    }
  }
}
