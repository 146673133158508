// Column Grid Wrappers
//
// Usage: `.m-12-column-grid` or `.m-2-column-grid` or @include column-grid($parameters)
//
// A mixin rolls a custom grid layout of equal-width columns.
// We use this mixin to create 2- and a 12-column grids, but more can be added using the mixin.
//
// Parameters:
// $columns - number of grid columns
// $column-padding
// $column-gap
// $row-gap
// $wrapper-base-width
//
// Widths of children can be 1-`$columns` columns and are set by using a modifier class (.e.g. `.m-12-column-grid__child--8`)
// The modifiers `&--bleed-[left/right]` can also be used on children to extend the child width beyond the grid
// to the edge of the outer container. On mobile, all children span all columns.
//
// Flexbox is supplied as the default display property, with Grid as an enhancement for supportive browsers using `@supports`.
//
// Styleguide Modules.TwoColumn

@use 'sass:math';

/* stylelint-disable-next-line */
@mixin column-grid($columns: 12, $column-padding: padding(small), $column-gap: $column-padding, $row-gap: $column-padding, $wrapper-base-width: container-width(wide)) {
  $column-width: math.div($wrapper-base-width, $columns);

  @include container-wide;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  // Use CSS Grid if supported
  //
  // Autoprefixer does not support autoplacement (mostly)
  // so test for a newer grid spec property to limit implementation,
  // with Flexbox as fallback
  // see https://github.com/postcss/autoprefixer#does-autoprefixer-polyfill-grid-layout-for-ie
  @supports (grid-auto-columns: min-content) {
    display: grid;
    grid-template-columns: repeat($columns, 1fr);
    row-gap: $row-gap;
    column-gap: $column-gap;
  }

  &--unpadded {
    $container-wide-width: $wrapper-base-width + $column-padding * 2;
    $unpadded-breakpoint: $container-wide-width + $column-padding * 2;

    @include fluid-scale(padding-right padding-left, 0px, $column-padding, $unpadded-breakpoint, $container-wide-width);
  }

  &--row-gap-large {
    > :not(:first-child) {
      @include fluid-scale(padding-top, 60px, 25px);
    }

    > :not(:last-child) {
      @include fluid-scale(padding-bottom, 60px, 25px);
    }
  }

  @include respond(break(60)) {
    column-gap: 0px;
    padding-right: $column-padding;
    padding-left: $column-padding;

    &__child {
      flex-basis: 100%;

      &:not(first-child) {
        margin-top: padding(small);
      }

      @supports (grid-auto-columns: min-content) {
        grid-column: span $columns;

        &:not(first-child) {
          margin-top: 0;
        }
      }
    }
  }

  @include respond(#{break(60) + 1}, min) {
    // create class names for different column spans
    // e.g. .m-12-column-grid__child--8 { grid-column: span 8 };
    @for $i from 1 through $columns {
      &__child--#{$i} {
        flex-basis: calc(#{math.div($i, $columns) * 100%} - #{$column-padding * 2});

        @supports (grid-auto-columns: min-content) {
          grid-column: span #{$i};
        }
      }
    }

    &__child--bleed-right,
    &__child--bleed-left {
      flex-grow: 1;
    }

    &__child--bleed-left {
      margin-right: $column-padding;
      margin-left: -$column-padding;

      @supports (grid-auto-columns: min-content) {
        margin-right: 0;
      }
    }

    &__child--bleed-right {
      margin-right: -$column-padding;
      margin-left: $column-padding;

      @supports (grid-auto-columns: min-content) {
        margin-left: 0;
      }
    }
  }

  // The following two media queries just handle resizing of grid children
  // that should be full bleed to the outer edge of the outer container.
  // This is used by the Two Column CE, for instance.
  @include respond($wrapper-base-width, min) {
    &__child--bleed-left {
      margin-left: calc((100vw - #{container-width(wide)}) / -2);  // fallback
      margin-left: calc(((var(--vw, 1vw) * 100) - #{container-width(wide)}) / -2);
    }

    &__child--bleed-right {
      margin-right: calc((100vw - #{container-width(wide)}) / -2); // fallback
      margin-right: calc(((var(--vw, 1vw) * 100) - #{container-width(wide)}) / -2);
    }
  }

  @include respond(container-width(max), min) {
    &__child--bleed-left {
      margin-left: math.div(container-width(max) - container-width(wide), -2);
    }

    &__child--bleed-right {
      margin-right: math.div(container-width(max) - container-width(wide), -2);
    }
  }
}

.m-12-column-grid {
  @include column-grid;
}

.m-2-column-grid {
  @include column-grid(2, padding(small), 10.442%, padding(small));
}
