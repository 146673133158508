// Secondary Button
//
// Markup:
// <button class="m-button-secondary">Learn More</button>
// <a href="#" class="m-button-secondary">See Gallery</a>
//
// Styleguide Modules.SecondaryButton

.m-button-secondary {
  @include button-unstyled;
  @include heading-quinary;
  @include hover-fade($hover-opacity-60);
  display: inline-flex;
  align-items: center;
  color: palette(neutral-75);
  background-color: transparent;

  &::after {
    display: inline;
    width: 2.286em;
    height: 0.625em;
    margin-bottom: 0.25em;
    margin-left: 0.5em;
    vertical-align: middle;
    content: '';
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 8'%3E%3Cpath fill='%232e2e30' d='M3.5 3.5h25v1h-25z' /%3E%3C/svg%3E");
  }
}
