// Icon List
//
// Markup: kss-markup/icon-list.html
//
// Styleguide Modules.IconList

.m-icon-list {
  @include list-unstyled;
  display: flex;

  &__item {
    & + & {
      @include fluid-scale(margin-left, 18px, 11px);
    }
  }

  &__link {
    @include hover-fade($hover-opacity-60);
  }

  &__icon {
    @include fluid-scale(width height, 64px, 42.667px);

    .m-icon-list--small & {
      @include fluid-scale(width height, 54px, 42.667px);
    }
  }
}
