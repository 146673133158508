.m-hero-header-secondary {
  @include container;
  position: relative;

  &__inner {
    padding-top: calc(var(--header-height, 130px) + 100px);
    margin-top: calc(var(--header-height, 130px) * -1);

    &--bottom-padding-large {
      @include fluid-scale(padding-bottom, 145px, 95px);
    }

    &--bottom-padding-small {
      padding-bottom: 10px;
    }

    @include respond(break(60)) {
      padding-top: calc(var(--header-height, 76px) + 132px);
    }
  }

  &__heading {
    @include heading-quinary;
  }

  &__subheading {
    @include fluid-scale(margin-top, padding(medium-small), padding(x-small));
    @include heading-secondary;
    width: 75%;
    max-width: 650px;
    line-height: 1.5;
  }

  &__heading-text-narrow {
    max-width: 550px;
  }
}
