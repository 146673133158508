.m-navigation-primary {
  @include list-unstyled;

  @include respond(break(60)) {
    display: none;
  }

  // &__item {}

  &__link {
    @include hover-recolor;
    font-weight: $semibold;
    text-transform: uppercase;
    letter-spacing: 0.038em;

    &.is-hovering {
      color: palette(primary);
    }
  }

  &__title {
    .m-navigation-primary__link.is-active & {
      position: relative;

      &::before {
        position: absolute;
        bottom: -12px;
        left: 50%;
        width: 100%;
        height: $underline-width;
        content: '';
        background-color: palette(primary);
        transform: translateX(-50%);
      }
    }
  }

  &__submenu-bg {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    visibility: hidden;
    background-color: rgba(palette(white), 0.975);
    opacity: 0;
    transform: translateY(-100%);

    &.is-invisible {
      transition:
        height $duration-nav-submenu-short ease,
        opacity $duration-nav-submenu-long ease,
        visibility $duration-nav-submenu-long ease,
        transform $duration-nav-submenu-long ease;
    }

    &.is-visible {
      visibility: visible;
      opacity: 1;
      transition:
        height $duration-nav-submenu-short ease,
        opacity $duration-nav-submenu-short ease,
        visibility $duration-nav-submenu-short ease,
        transform $duration-nav-submenu-short ease;
      transform: translateY(-3px);
    }
  }
}
