.m-artwork {
  line-height: 1;

  &[data-behavior='OpenImageGridSlideshow'] {
    @include focus-default;
    cursor: pointer;
  }

  &--padded-top {
    @include respond(#{break(60) + 1}, min) {
      margin-top: 20vw;
    }

    @include respond(container-width(wide), min) {
      margin-top: 212px;
    }
  }

  &--padded-top-small {
    @include respond(#{break(60) + 1}, min) {
      margin-top: 8.49vw;
    }

    @include respond(container-width(wide), min) {
      margin-top: 90px;
    }
  }

  &__wrapper {
    overflow: hidden;
    background-color: inherit;
    transition: background-color 0.5s ease;

    .m-artwork.is-hovering & {
      background-color: palette(pink);
    }
  }

  &__matte {
    @include respond(#{break(60) + 1}, min) {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 9.433vw;
    }

    @include respond(container-width(wide), min) {
      padding: 100px;
    }
  }

  &__image {
    display: block;

    &--matted {
      @include respond(#{break(60) + 1}, min) {
        max-width: 500px;
      }
    }
  }

  &__matte,
  &__image {
    @include link-image-fade;

    .m-artwork.is-hovering & {
      @include link-image-hover-opacity;
    }
  }

  &__caption {
    @include fluid-scale(font-size, 14px, 11px);
    padding-top: 1.25em;
    font-family: $font-stack-base;
    font-weight: $regular;
    line-height: 1.667;
    letter-spacing: 0.016em;
  }
}
