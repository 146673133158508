.m-tab-container {
  > *:not(.l-no-top-padding) {
    @include padding-vertical-top-only;
  }

  > *:not(.l-no-bottom-padding) {
    @include padding-vertical-bottom-only;
  }

  > .a-bg-white {
    padding-top: 0;
  }
}
