// Waypoint Navigation Wrapper
//
// Module wrapping the waypoint navigation component. Gets a specific height
// based on a CSS custom property set in JS that corresponds to the internal
// height of the navigation. This is required in order to preserve the
// y-pos (and thus scroll calculation) of page elements when the nav gets
// unpinned on scroll. If the custom property doesn't exist, will fallback to
// `0px` so that the module doesn't take up any space.
//
// Styleguide Modules.Waypoint

.m-waypoint-nav-wrapper {
  height: var(--waypoint-nav-height, 0px);
}
