// Thumbnail List
//
// A 3-column grid layout for thumbnail blocks. Uses a CSS auto-placing grid,
// with flexbox as a fallback.
//
// Styleguide Modules.ThumbnailList

@use 'sass:math';

.m-thumbnail-list {
  $column-gap: 43px;
  $row-gap-desktop: 70px;
  $row-gap-mobile: 62px;
  $item-min-width-mobile: 260px;
  $item-min-width-desktop: 324px;

  @include list-unstyled;
  @include container-wide;
  @include fluid-scale(margin-top, -$row-gap-desktop, -$row-gap-mobile);
  position: relative;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  // use auto-placing grid if browser supports it
  @supports (grid-auto-columns: min-content) {
    @include fluid-scale(row-gap, $row-gap-desktop, $row-gap-mobile);
    display: grid;
    grid-template-columns:
      repeat(
        auto-fill,
        minmax($item-min-width-mobile, 1fr)
      );
    column-gap: $column-gap;
    justify-content: start;
    margin-top: 0;
  }

  &--loading {
    // set a min-height to accommodate height of loader
    @include fluid-scale(min-height, 500px, 230px);
  }

  &__item {
    @include fluid-scale(
      min-width,
      $item-min-width-desktop,
      $item-min-width-mobile
    );
    @include fluid-scale(padding-top, $row-gap-desktop, $row-gap-mobile);
    flex-basis: calc(#{math.percentage(math.div(1, 3))} - #{$column-gap});

    .m-thumbnail-list--loading & {
      opacity: 0;
    }

    @include respond(1100px) {
      flex-basis: calc(50% - #{$column-gap * 0.5});
    }

    @include respond(720px) {
      flex-basis: 100%;
      min-width: 100%;
    }

    // use grid is supported (flex-basis values will be ignored)
    @supports (grid-auto-columns: min-content) {
      min-width: 100%;
      padding-top: 0;

      @include respond(390px) {
        grid-template-columns: repeat(auto-fit, 100%);
      }
    }
  }
}
