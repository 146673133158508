.m-cta-block {
  display: flex;
  justify-content: space-between;

  @include respond(break(60)) {
    flex-direction: column;
  }

  &--narrow-padding {
    @include fluid-scale(padding-right padding-left, padding(medium-large), 0px);
  }

  &__thumbnail {
    max-width: 151px;
    max-height: 168px;
  }

  &__thumbnail-container {
    display: flex;
    align-items: center;
    padding-right: padding(medium-small);
  }

  &__heading-container {
    flex: 1;

    @include respond(break(60)) {
      flex-basis: auto;
      padding-top: padding(x-small);
    }
  }

  &__text-primary {
    color: palette(neutral-75);
  }

  &__text-secondary {
    color: palette(neutral-60);
  }

  &__button-container {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-end;
    padding-left: padding(medium-small);

    @include respond(break(60)) {
      flex-basis: auto;
      justify-content: flex-start;
      padding-top: padding(x-small);
      padding-left: 0;
    }
  }
}
