// Entity Block
//
// A media block containing a heading, large image, and some kind of descriptive information.
// Usually linked to a detail page. The BEM block mostly handles appearance styles, while
// layout is handled by the `m-12-column-grid` block.
//
// Styleguide Modules.EntityBlock

.m-entity-block {
  @include fluid-scale(
    padding-top padding-bottom,
    padding(x-large),
    padding(medium)
  );
  color: palette(neutral-75);

  &.a-bg-white {
    & + & {
      padding-top: 0;
    }
  }

  &:not(.a-bg-white) {
    & + & {
      @include fluid-scale(margin-top, padding(x-large), padding(medium));

      @include respond(break(60)) {
        margin-top: 0;
      }
    }
  }

  &__link {
    display: block;
    max-width: container-width(wide);
    margin-right: auto;
    margin-left: auto;
    color: inherit;
    text-decoration: none;
  }

  // &__inner {
  // }

  &__heading {
    display: flex;
    flex-direction: column;
    width: 100%;

    &--column {
      @include fluid-scale(margin-top, padding(large), padding(medium));

      @include respond(break(60)) {
        margin-top: 0;
      }
    }

    &--row {
      @include respond(break(60) + 1, min) {
        flex-direction: row;
        justify-content: space-between;
      }
    }
  }

  &__title {
    @include fluid-scale(margin-bottom, padding(x-small), 12px);
    @include heading-secondary;
    line-height: 1.5;
    white-space: pre-line;

    @include respond(break(60)) {
      line-height: 1.417;
      letter-spacing: 0;
    }

    .m-entity-block__heading--row & {
      flex-grow: 1;
      max-width: 600px;
    }
  }

  &__description {
    @include copy-bold;

    .m-entity-block.a-bg-white & {
      color: palette(neutral-60);
    }

    /* stylelint-disable no-descending-specificity */
    .m-entity-block__heading--row & {
      @include respond(break(60) + 1, min) {
        flex-shrink: 0;
        margin-left: padding(small);
      }
    }
  }

  &__figure {
    position: relative;
    align-self: flex-start;
    order: -1;
    transition: background-color 0.5s ease;

    .m-entity-block__link:hover &,
    .m-entity-block__link:focus & {
      background-color: palette(pink);
    }

    &.m-12-column-grid__child--7 {
      @include fluid-scale(margin-right, padding(small), 0px);
    }
  }

  &__image {
    display: block;
    width: 100%;
    height: auto;
    backface-visibility: hidden; // prevents jumping on opacity change
    transition: opacity 0.5s ease;

    .m-entity-block__link:hover &,
    .m-entity-block__link:focus & {
      opacity: 0.333;
    }
  }

  &__footer {
    display: none;
    margin-top: 40px;
    text-align: right;

    @include respond(break(60)) {
      display: block;
    }
  }

  &__hover-box {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.5s ease;

    .m-entity-block__link:hover &,
    .m-entity-block__link:focus & {
      @include respond(break(60) + 1, min) {
        opacity: 1;
      }
    }
  }

  &__details-text {
    @include heading-secondary;
    @include fluid-scale(margin-right, 20px, 5px);

    &--small {
      @include respond(break(60)) {
        font-size: 12px;
      }
    }
  }

  &__details-svg {
    @include fluid-scale(width, 100px, 36px);
    display: inline;
    max-width: 20%;
    vertical-align: middle;
    stroke-width: 2px;
  }
}
