.m-pressrelease-list {
  padding-top: 15px;

  &__item-container {
    @include fluid-scale(padding-top padding-bottom, padding(medium-large), padding(small));

    &:not(:last-child) {
      border-bottom: 1px solid palette(neutral-30);
    }
  }

  &__heading-filter {
    text-align: center;
  }

  &__subheading {
    @include fluid-scale(padding-bottom, padding(medium-small), padding(x-small));
  }

  &__link,
  &__heading-filter {
    color: palette(neutral-75);
  }

  &__link {
    @include hover-recolor;
    display: block;
  }

  &__date {
    padding-bottom: 4px;
    color: palette(neutral-60);
  }
}
