.m-flex-wrapper {
  display: flex;
  flex-flow: row wrap;

  &__child {
    flex-basis: 50%;
    flex-grow: 1;
    margin-bottom: 20px;
  }
}
