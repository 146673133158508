.m-entity-list-footer {
  @include container-wide;
  @include fluid-scale(margin-top, 90px, padding(medium-large));
  @include fluid-scale(padding-top padding-bottom, padding(medium-large), padding(medium-small));
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-top: 1px solid #dfdfe3;
  border-bottom: 1px solid #dfdfe3;

  &__text {
    @include heading-tertiary;
  }

  &__icon {
    margin-left: 12px;
    fill: palette(primary);
  }
}
