// Entity Block List
//
// List and spacing styles for a simple stacked list of blocks.
//
// Styleguide Modules.EntityBlockList

.m-entity-block-list {
  @include list-unstyled;
  position: relative;

  &--loading {
    // set a min-height to accommodate height of loader
    @include fluid-scale(min-height, 500px, 230px);
  }

  &__item {
    .m-entity-block-list--loading & {
      opacity: 0;
    }
  }
}
