// Global z-index stack
// -----------------------------------

.m-overlay__close,
.m-overlay__footer {
  z-index: 150;
}

.m-overlay.is-open {
  z-index: 100;

  &.m-overlay--nested {
    z-index: 125;
  }
}

.m-entity-list-filters {
  &__option-listbox,
  &__close-button,
  &__reset-button {
    z-index: 125;
  }

  &__reset-button--is-active {
    z-index: 1;
  }
}

/* stylelint-disable-next-line selector-max-id */
.m-entity-list-filters__small-text--is-active,
.m-navigation-tabbed__dropdown.is-open,
newsletter-signup output {
  z-index: 100;
}

.m-header-primary {
  z-index: 75;
}

.m-navigation-tabbed.headroom--not-top {
  z-index: 50;
}

.has-scroll-lock::after {
  z-index: 40;
}

.m-loading-animation.is-visible {
  z-index: 35;
}

.m-hero-header-primary__inner {
  z-index: 25;
}

.m-navigation-tabbed__dropdown,
.m-loading-animation {
  z-index: -25;
}

.m-subnavigation-primary {
  z-index: 150;
}

.m-navigation-primary__submenu-bg {
  z-index: -50;
}
