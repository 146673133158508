// Accordion
//
// A shared component for handling collapsed text. Uses the `ExpandAccordion` JS class.
// Additional styling should be added using additional CSS classes.
//
// Parameters:
// $duration - the time it takes for the accordion to expand. Defaults to `0.35s`.
//
// Markup: kss-markup/accordion.html
//
// Styleguide Modules.Accordion

.m-accordion {
  $duration: 0.35s;
  transition: max-height $duration $duration ease-out;

  &.is-open {
    transition: max-height $duration ease-out;
  }

  &__preview {
    display: flex;
    align-items: center;
    width: 100%;
  }

  &__toggle {
    position: relative;
    height: 19px;
    transition: border-color $duration $timing;
  }

  &__icon {
    position: absolute;
    top: 0;
    left: 0;
  }

  &__icon--minus {
    transition: opacity $duration $duration linear;

    .m-accordion.is-open & {
      opacity: 0;
      transition: opacity $duration linear;
    }
  }

  &__full {
    visibility: hidden;
    opacity: 0;
    transition:
      opacity $duration ease-out,
      visibility $duration ease-out;

    .m-accordion.is-open & {
      visibility: visible;
      opacity: 1;
      transition:
        opacity $duration $duration ease-out,
        visibility $duration $duration ease-out;
    }
  }
}
