// Appearance
// --------------------

// Appearance mixins contain primarily texture parameters: background-color, transparency, borders, etc.

// These should be limited to appearance-related properties only.

// Visibility
// --------------------------------------------------------
.a-pos-rel {
  position: relative;
}

.a-pos-abs {
  position: absolute;
}

.a-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  border: 0;
}

.a-hide-overflow {
  overflow: hidden;
}

.a-hide-mobile {
  @include respond(break(60)) {
    display: none !important; // stylelint-disable-line
  }
}

.a-show-mobile {
  @include respond(break(60), min) {
    display: none !important; // stylelint-disable-line
  }
}

@mixin focus-default {
  .js-focus-visible &:focus:not(.focus-visible) {
    outline-width: 0;
  }

  &.focus-visible {
    outline: palette(primary) auto 4px;
    @content;
  }
}

@mixin hover-fade($value: $hover-opacity-90) {
  @include focus-default;
  transition: opacity 0.2s $timing;

  &:hover {
    opacity: $value;
  }
}

.a-hover-fade {
  @include hover-fade;
}

@mixin hover-recolor($color: palette(primary)) {
  transition: color 0.2s $timing;

  &:hover,
  &.focus-visible {
    color: $color;
    outline: none;
  }
}

.a-hover-recolor {
  @include hover-recolor;
}

.t-link-underlined {
  @include link-underlined;
  @include hover-fade($hover-opacity-60);
}

.t-simple-underline {
  @include simple-underline;
  @include hover-fade($hover-opacity-60);
  display: block;
}

// Color
// --------------------------------------------------------
// Create a `.a-color-` class for each color in $palette map
@each $name, $value in $palette {
  .a-color-#{$name + ""} {
    color: $value;

    --text-color: $value;
  }
}

// Create a `.a-bg-` class for each color in $palette map
@each $name, $value in $palette {
  .a-bg-#{$name + ""} {
    background-color: $value;

    &--semi-transparent {
      background-color: rgba($value, 0.85);
    }
  }
}

.a-bg-custom {
  background-color: var(--custom-background-color);
}

// Icons
// --------------------------------------------------------
@mixin proto-icon {
  display: inline;
  width: 1em;
  height: 1em;
  vertical-align: middle;
  fill: currentColor;
}

.a-icon {
  @include proto-icon;
}

.a-icon--large {
  width: 2em;
}

// Links, Buttons
// --------------------------------------------------------
@mixin button-unstyled {
  appearance: none;
  padding: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  background: transparent;
  border: 0;
}

// styles for flickity pagination; abstracted for use elsewhere
@mixin prev-next-button {
  background: palette(neutral-75);
  border-radius: 0;
  transition: opacity 0.2s linear;

  &:hover {
    background: palette(neutral-75);
  }

  /* stylelint-disable string-quotes */
  [class*="icon"] {
    position: static; // override flickity defaults
    width: 100%;
    height: 100%;
    fill: white;
    transition: transform $duration ease-out;
  }

  &.previous:hover {
    [class*="icon"] {
      transform: translateX(-5px);
    }
  }

  &.next:hover {
    [class*="icon"] {
      transform: translateX(5px);
    }
  }
  /* stylelint-enable string-quotes */

  &:disabled {
    pointer-events: none;
    opacity: 0;
  }
}

.a-prev-next-button {
  @include prev-next-button;
}

// Transitions
// --------------------------------------------------------

@mixin fadeIn($duration: 0.35s) {
  visibility: visible;
  opacity: 1;
  transition: opacity $duration $timing, visibility $duration $timing;
}

@mixin fadeOut($duration: 0.1s) {
  visibility: hidden;
  opacity: 0;
  transition: opacity $duration $timing, visibility $duration $timing;
}

@mixin link-image-background {
  background-color: palette(pink);
}

@mixin link-image-fade {
  backface-visibility: hidden; // prevents jumping on opacity change
  transition: opacity 0.5s ease;
}

@mixin link-image-hover-opacity {
  opacity: 0.333;
}

// Simple Block Class
// --------------------------------------------------------
.a-block {
  display: block;
}
