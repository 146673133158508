.m-exhibition-list {
  &__image {
    @include link-image-fade;
    height: 100%;
    object-fit: cover;
  }

  &__card-heading {
    @include fluid-scale(padding-bottom, 9px, 2px);
    transition: opacity 0.2s $timing;
  }

  &__link-wrapper {
    &:hover {
      .m-exhibition-list__image {
        @include link-image-hover-opacity;
      }

      .m-exhibition-list__card-heading {
        opacity: $hover-opacity-60;
      }
    }
  }

  &__image-background {
    @include link-image-background;
    @include fluid-scale(height, 245px, 231px);
    display: block;
  }

  &__card {
    @include respond(break(60)) {
      width: 82vw;
      padding-top: 0;
      padding-left: padding(small);
    }
  }

  &__desktop {
    @include respond(break(60)) {
      display: none;
    }
  }

  &__mobile {
    display: none;

    @include respond(break(60)) {
      display: block;
    }
  }

  &__desktop-button {
    @include respond(break(60)) {
      display: none;
    }
  }

  &__mobile-button-container {
    display: none;

    @include respond(break(60)) {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__mobile-button {
    margin-top: padding(small);
    margin-right: padding(small);
    margin-left: padding(small);

    @include respond(break(40)) {
      justify-content: space-between;
      width: 100%;
    }
  }

  &__list {
    @include list-unstyled;
    display: flex;
    flex-wrap: wrap;

    @supports (grid-template-columns: 1fr 1fr 1fr) {
      @include fluid-scale(grid-row-gap, 95px, 50px);
      @include fluid-scale(grid-column-gap, padding(medium-large), padding(x-small));
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
}
