.m-offset-hero {
  position: relative;

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 1083px;
    height: 501px;
    object-fit: cover;

    @include respond(break(140)) {
      width: 75vw;
      height: 34.722vw;
    }

    @include respond(break(90)) {
      height: 40vw;
    }

    @include respond(break(60)) {
      width: 100%;
      height: 100%;
    }
  }

  &__text-container {
    @include fluid-scale(padding-top padding-bottom, 105px, padding(small));
    @include fluid-scale(padding-left padding-right, 95px, padding(small));
    position: relative;
    display: inline-block;
    width: 570px;
    min-height: 438px;
    margin-top: padding(x-large);
    margin-left: 52%;

    @include respond(break(140)) {
      width: 44vw;
      margin-left: 50%;
    }

    @include respond(break(100)) {
      width: 55vw;
      min-height: 30.417vw;
      margin-left: 45vw;
    }

    @include respond(break(90)) {
      width: 60vw;
      margin-left: 40vw;
    }

    @include respond(break(60)) {
      display: flex;
      align-items: flex-end;
      width: 100%;
      min-height: 64vw;
      margin-top: 0;
      margin-left: 0;
    }
  }

  &__subheading:not(:first-child) {
    @include fluid-scale(margin-top, padding(x-small), padding(x-x-small));
  }

  &__button:not(:first-child) {
    @include fluid-scale(margin-top, padding(small), padding(x-small));
  }
}
