// Base
// --------------------------------------------------------

// Applies a natural box layout model to all the things
*,
*::before,
*::after {
  box-sizing: inherit;
}

// Global
// --------------------------
html {
  // Applies a natural box layout model to all the things
  box-sizing: border-box;

  // Set the base font size here, which will correspond to 1em inside <body>
  font-size: 100%;
}

body {
  // ensure page is always at least 100vh tall; `main` expands to fill space
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  color: palette(neutral50);
}

main {
  flex-grow: 1;
}

a {
  @include focus-default;
  color: inherit;
  text-decoration: none;

  &:hover {
    // color: $something;
  }

  &:active {
    // color: $something;
  }

  &:visited {
    // color: $something;
  }
}

button {
  @include button-unstyled;
  @include focus-default;
}
