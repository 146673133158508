.m-slideshow-figure {
  @mixin fade-in($delay: false) {
    $duration: 0.15s;
    opacity: 0.2;

    @if $delay == true {
      transition: opacity $duration $duration ease;
    } @else {
      transition: opacity $duration ease;
    }

    .is-selected & {
      opacity: 1;
    }
  }

  $slide-max-width: 1200px;
  $slide-lateral-margin: 70px;

  @include fade-in;
  @include fluid-scale(margin-right margin-left, $slide-lateral-margin, padding(small));
  @include fluid-scale(padding-bottom, $slide-lateral-margin, padding(small));
  opacity: 0.2;
  transition: opacity 0.15s ease;

  &__image {
    display: block;
    max-width: $slide-max-width;
    height: auto;
    max-height: 60vh;
    margin: 0 auto;

    @include respond(788px + $slide-lateral-margin * 2) {
      max-width: 80vw;
      height: auto;
      max-height: 55vh;
    }
  }

  &__heading,
  &__caption {
    @include fade-in(true);
  }

  &__heading {
    @include heading-tertiary;
    max-width: 500px;
    padding-top: padding(x-small);
    margin: 0 auto;
    text-align: center;
  }

  &__caption {
    @include copy-primary;
    max-width: 500px;
    margin: 40px auto 0;
    text-align: center;
  }
}
