.psam-logo {
  &--white {
    .psam-logo__mark,
    .psam-logo__text {
      fill: palette(white);
      transition:
        opacity $duration-nav-submenu-long ease,
        fill $duration-nav-submenu-long ease;
    }

    .psam-logo__mark--light {
      opacity: 0.7;
    }
  }

  &--orange,
  .m-header-primary--transparent-white.headroom--not-top &,
  .m-header-primary--transparent-white:hover & {
    transition:
      opacity $duration-nav-submenu-long ease,
      fill $duration-nav-submenu-long ease;

    .psam-logo__mark--light {
      opacity: 1;
      fill: #ff972f;
    }

    .psam-logo__mark--dark {
      fill: #fe6c27;
    }

    .psam-logo__text {
      fill: palette(neutral-75);
    }
  }
}
