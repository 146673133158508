.m-tabbed-content {
  margin: 0 auto;
  overflow: hidden;
  transition: height 0.3s $timing;

  @include respond(break(60)) {
    overflow: visible;
  }

  &__top-padding {
    @include fluid-scale(padding-top, 115px, 65px, break(110), break(70));

    @include respond(break(60)) {
      padding-top: 0;
    }
  }

  &__hide-mobile {
    @include respond(break(60)) {
      display: none;
    }
  }

  &__tab-nav-outer {
    position: relative;
    text-align: center;

    &--hide-mobile {
      @include respond(break(80)) {
        display: none;
      }
    }
  }

  &__tab-nav-inner {
    @include list-unstyled;
    display: flex;
    align-items: baseline;
    margin-right: 2px;
    border-bottom: 1px solid palette(neutral-25);

    @include respond(break(90)) {
      margin-right: 8px;
    }

    &--even-tabs {
      justify-content: center;
    }

    &--varied-width-tabs {
      justify-content: space-around;
    }
  }

  &__tab-nav-header {
    position: relative;
    display: block;

    &--even-tabs {
      flex: 1;
    }
  }

  &__result-count {
    padding-left: 5px;
    color: palette(primary);
  }

  &__tab-nav-header-link {
    @include fluid-scale(
      padding-bottom,
      padding(medium-small),
      padding(x-x-small)
    );
    @include hover-recolor;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    font-weight: $semibold;
    text-align: center;
    transition:
      color $duration $timing,
      bottom $duration $timing,
      font-size $duration $timing,
      font-weight $duration $timing;

    @include respond(break(90)) {
      @include copy-small;
    }

    &--inactive {
      color: palette(neutral-60);
    }

    &--active {
      @include heading-secondary;
      bottom: -2px;
      color: palette(primary);

      @include respond(break(110)) {
        @include heading-tertiary;
      }
    }
  }

  &__tab-nav-header-link-simple {
    @include heading-tertiary;
    @include fluid-scale(
      padding-bottom,
      padding(medium-small),
      padding(x-x-small)
    );
    @include fluid-scale(
      padding-right padding-left,
      padding(medium-small),
      padding(x-x-small)
    );
    display: inline-block;
    width: 100%;
  }

  &__panels {
    position: relative;
    overflow: hidden;
  }

  &__level {
    position: absolute;
    top: 0;
    width: 100%;
    opacity: 1;
  }

  &__placeholder-list {
    display: flex;
    overflow: hidden;
    pointer-events: none;
    visibility: hidden;
    opacity: 0;

    .m-tabbed-content__level {
      position: static;
    }
  }

  &__placeholder {
    width: 100%;
  }

  &__visibility {
    opacity: 0;

    &.m-tabbed-content__level {
      opacity: 1;
      transition: opacity $duration $timing;
    }
  }

  &__level-inner {
    @include fluid-scale(padding-top, padding(large), padding(small));
    text-align: center;
  }

  &__heading {
    @include fluid-scale(
      padding-bottom,
      padding(medium-small),
      padding(x-small)
    );
  }

  &__description {
    @include fluid-scale(padding-bottom, padding(medium), 19px);
  }

  &__mobile-category {
    padding-bottom: 5px;
    border-bottom: 2px solid palette(primary);
  }

  &__tab-marker {
    position: absolute;
    bottom: 0;
    height: 3px;
    background-color: palette(primary);
    transition: width 0.1s $timing, left $duration $timing;
  }
}
