.m-mega-button {
  @include hover-fade($hover-opacity-70);
  text-align: center;

  &__heading {
    @include fluid-scale(padding-bottom, padding(small), padding(x-x-small) );
  }

  &__link-text {
    display: inline-block;
  }

  &__icon {
    display: inline-block;
    height: 38px;
    padding-left: 10px;
    vertical-align: middle;

    @include respond(break(60)) {
      display: none;
    }
  }
}
