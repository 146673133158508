.m-hero-primary {
  @include container;
  position: relative;
  overflow: hidden;

  &--default-container {
    @include fluid-scale(height, 500px, 254px);
  }

  &--short-container {
    @include fluid-scale(height, 532px, 208px);
  }

  &__image {
    display: block;
    width: 100%;
    object-fit: cover;

    &--default {
      height: 100%;
    }

    &--tall {
      @include fluid-scale(height, 667px, 339px);
    }
  }

  &__video-toggle {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
  }

  &__video-toggle-icon {
    @include fluid-scale(width height, 118px, 75px);
  }
}
