.m-subnavigation-primary {
  @include container-x-wide;
  position: absolute;
  top: 100%;
  left: 50%;
  width: 100%;
  color: palette(neutral-75);
  visibility: hidden;
  opacity: 0;
  transform: translateX(-50%);

  &.is-closed {
    transition: opacity 0.2s ease, visibility 0.2s ease;
  }

  &.is-open {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.2s 0.2s ease, visibility 0.2s 0.2s ease;
  }

  &__wrapper {
    @include container-x-wide;
    @include fluid-scale(padding-top, 36px, 30px);
    @include fluid-scale(padding-bottom, 67px, 50px);
    @include fluid-scale(padding-left padding-right, 57px, 30px);
    padding-top: 36px;
    padding-bottom: 67px;
    text-align: center;
  }

  &__heading {
    @include heading-primary;
  }

  &__heading-link {
    position: relative;
    display: inline-block;
    padding: 15px;

    &::before {
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 89px;
      height: $underline-width;
      content: '';
      background-color: palette(primary);
      transform: translateX(-50%);
    }
  }

  a.m-subnavigation-primary__heading-link {
    // only use if is an `<a>`
    @include hover-recolor;
  }

  &__list {
    @include list-unstyled;
    display: flex;
    justify-content: center;
  }

  &__item {
    flex-grow: 1;
    width: 100%;

    &--normal {
      max-width: 332px;
    }

    &--wide {
      max-width: 412px;
    }
  }

  &__link-block {
    @include fluid-scale(padding-top padding-bottom, 45px, 30px);
    display: block;

    &--normal {
      @include fluid-scale(padding-left padding-right, 57px, 20px);
    }

    &--wide {
      @include fluid-scale(padding-left padding-right, 30px, 30px);
    }
  }

  &__main-link[href],
  &__sub-link[href] {
    @include hover-recolor;
  }

  &__main-link {
    @include heading-tertiary;
    display: block;

    & + & {
      padding-top: 30px;
    }
  }

  &__sub-link {
    @include copy-primary;
    display: block;
    margin-top: 10px;
  }
}
