@use 'sass:math';

.m-three-column {
  &__heading {
    @include fluid-scale(padding-bottom, padding(large), padding(small));
    text-align: center;
  }

  &__column-container {
    display: flex;
    justify-content: space-between;

    @include respond(break(60)) {
      flex-direction: column;
    }
  }

  &__image {
    @include fluid-scale(margin-bottom, padding(x-small), padding(x-x-small));
  }

  &__column {
    flex: 1;
    min-width: 0;
    max-width: math.percentage(math.div(1, 3));

    &:not(:last-child) {
      @include fluid-scale(
        margin-right,
        padding(large),
        padding(small),
        break(110),
        break(80)
      );

      @include respond(break(60)) {
        margin-right: 0;
      }
    }

    @include respond(break(60)) {
      flex: 0 auto;
      width: 100%;
      max-width: 100%;

      &:not(:first-child) {
        padding-top: padding(small);
      }
    }
  }
}
