// Loading animation for AJAX & API-loaded content
// Must be nested in a container with `position: relative`
.m-loading-animation {
  @include fluid-scale(height, 700px, 400px);
  position: absolute;
  top: 0;
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: container-width(max);
  transform: translateX(-50%);

  .m-entity-block-list & {
    @include fluid-scale(min-height, 600px, 300px);
  }

  // limit scope of opacity effects to not conflict with React transitions
  &.is-behavior {
    opacity: 0;
    transition: opacity 0.2s;

    &.is-visible {
      opacity: 1;
    }
  }

  &__image {
    @include fluid-scale(width, 225px, 150px);
    display: block;
  }
}
